import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Carrito from "./compra/carrito";
import {
  FaShoppingCart,
  FaInfoCircle,
  FaCalendarAlt,
  FaClock,
  FaMapMarkerAlt,
  FaEuroSign,
} from "react-icons/fa";
import Navbar from "../components/Navbar";
import { EnvironmentContext } from "../EnvironmentVariables";
import Permiso from "../log/Permiso";
import ComponenteContenedor from "./compra/componenteContenedor";
import TarimaImage from "../img/tarima.jpeg"; // Importa la imagen

function Compra() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/infoeventos.php?id=${id}`
        );
        const eventData = response.data.find((item) => item.id === id);
        setEvent(eventData);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, [id, backendUrl]);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const handleCloseModal = () => {
    setIsCartOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsCartOpen(false);
    }
  };

  const handleAsientosSeleccionadosChange = (seats) => {
    setSelectedSeats(seats);
  };

  const handleAsientosSeleccionadosCompra = (asientosSeleccionados) => {
    setSelectedSeats(asientosSeleccionados);
    toggleCart(); // Abre el carrito cuando se seleccionan asientos
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    let hourInt = parseInt(hour);
    const amPm = hourInt >= 12 ? "PM" : "AM";
    hourInt = hourInt % 12 || 12;
    return `${hourInt}:${minute} ${amPm}`;
  };

  const tipoEvento =
    event &&
    (event.costvip === "0.00"
      ? "Entradas normales"
      : event.cost === "0.00"
        ? "Evento Gratis"
        : "Contiene Asientos Vip");

  return (
    <>
      <Navbar />
      <Permiso />
      {event && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-2xl mx-auto">
          <div className="relative overflow-hidden rounded-lg md:rounded-none md:rounded-l-lg">
            <img
              alt="Event Banner"
              className={`aspect-video w-full h-full object-cover ${
                window.innerWidth < 768 ? "" : "rounded-lg"
              }`}
              src={event.image_url}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-200 to-transparent rounded-lg" />
          </div>
          <div className="p-6 space-y-4 text-black">
            <h1 className="text-xl md:text-2xl font-bold">{event.title}</h1>
            <div className="flex items-center space-x-2 text-sm">
              <FaCalendarAlt />
              <span>{event.date}</span>
              <FaClock />
              <span>{convertTo12HourFormat(event.time)}</span>
            </div>
            <div className="flex items-center space-x-2 text-sm">
              <FaMapMarkerAlt />
              <span>{event.lugar}</span>
            </div>
            <div className="flex items-center space-x-2 text-sm">
              <FaEuroSign />
              <span>{event.cost}</span>
              {event.costvip !== "0.00" && (
                <>
                  <span>(VIP: {event.costvip})</span>
                </>
              )}
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-600">
              {event.description}
            </p>
            <div className="flex items-center space-x-2 text-sm">
              <span>{tipoEvento}</span>
            </div>
            <div className="flex justify-end md:justify-start">
              <button onClick={toggleCart} className="text-black">
                <FaShoppingCart className="text-3xl" />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="w-full max-w-2xl mt-6 bg-yellow-500 text-yellow-900 p-4 mx-auto rounded-lg text-xs">
        <div className="flex items-center space-x-2">
          <FaInfoCircle className="h-6 w-6" />
          <p>
            Las entradas normales son por orden de llegada y zona elegida, las
            entradas Vip te permiten elegir donde deseas sentarte en una zona
            Vip.
          </p>
        </div>
      </div>

      <div className="w-full max-w-xs mt-6 mx-auto p-4 rounded-lg">
        <img
          src={TarimaImage}
          alt="Tarima"
          className="w-full h-auto rounded-lg transform rotate-90"
        />
      </div>

      <ComponenteContenedor
        event={event}
        tipoEvento={tipoEvento}
        onAsientosSeleccionadosChange={handleAsientosSeleccionadosChange}
        onAsientosSeleccionadosCompra={handleAsientosSeleccionadosCompra} // Agregar esta prop
      />

      {isCartOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleOverlayClick}
        >
          <div className="relative">
            <Carrito selectedSeats={selectedSeats} onClose={handleCloseModal} />
            <button
              className="absolute top-0 right-0 mt-4 mr-4 text-black"
              onClick={handleCloseModal}
            ></button>
          </div>
        </div>
      )}
    </>
  );
}

export default Compra;

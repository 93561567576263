import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <section className="relative bg-[#f5f8fe] overflow-hidden">
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
        <div className="mx-auto flex max-w-3xl flex-col items-center text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 76 76"
            fill="none"
            preserveAspectRatio="xMidYMid meet"
            className="mb-9 w-20 text-[#386eec]"
          >
            <path
              d="M60.1667 31.6663H63.3333C65.0823 31.6663 66.5 33.0841 66.5 34.833V66.4997C66.5 68.2486 65.0823 69.6663 63.3333 69.6663H12.6667C10.9178 69.6663 9.5 68.2486 9.5 66.4997V34.833C9.5 33.0841 10.9178 31.6663 12.6667 31.6663H15.8333V28.4997C15.8333 16.2574 25.7577 6.33301 38 6.33301C50.2423 6.33301 60.1667 16.2574 60.1667 28.4997V31.6663ZM53.8333 31.6663V28.4997C53.8333 19.7552 46.7444 12.6663 38 12.6663C29.2555 12.6663 22.1667 19.7552 22.1667 28.4997V31.6663H53.8333ZM34.8333 44.333V56.9997H41.1667V44.333H34.8333Z"
              fill="currentColor"
            ></path>
          </svg>
          <h1 className="mb-4 text-4xl font-bold uppercase md:text-5xl">
            Acceso Denegado
          </h1>
          <p className="mb-2 w-full max-w-lg text-lg text-black md:text-xl">
            No cuentas con los permisos para acceder a esta sección.
          </p>
          <p className="text-lg md:text-xl">
            Por favor verifique sus permisos{" "}
            <Link to="/" className="text-[#386eec] underline">
              Volver
            </Link>{" "}
          </p>
        </div>
      </div>
      <img
        src="https://assets-global.website-files.com/655eda66dab018e613baf5ff/655f72fe99aeec852aa8c385_cta-background-image2.svg"
        loading="lazy"
        alt=""
        className="absolute -top-10 w-14 rotate-90 lg:bottom-0 lg:top-auto lg:w-32"
      />
      <img
        src="https://assets-global.website-files.com/655eda66dab018e613baf5ff/655eedbe0ca0432ba7fd096f_guide-svg2.svg"
        loading="lazy"
        alt=""
        className="absolute right-0 top-5 w-20 -rotate-90 lg:w-32"
      />
      <img
        src="https://assets-global.website-files.com/655eda66dab018e613baf5ff/655f2b28c57aa4c75abf6974_guide-svg4.svg"
        loading="lazy"
        alt=""
        className="-bottom-10 right-0 hidden w-28 -rotate-90 md:absolute md:block lg:-bottom-20 lg:w-40"
      />
    </section>
  );
};

export default ErrorPage;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { EnvironmentContext } from "../../EnvironmentVariables";
import {
  FaCouch,
  FaMapMarkerAlt,
  FaShoppingCart,
  FaCheckCircle,
} from "react-icons/fa";

const Vip = ({ onAsientosSeleccionadosChange, tipoEvento }) => {
  const [loading, setLoading] = useState(true);
  const [seatGroups, setSeatGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("F");
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const { id } = useParams();
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/asientos.php?eventId=${id}`
        );
        const filteredSeats = response.data.filter((seat) =>
          ["F", "G", "H", "I", "J"].includes(seat.groupnumber)
        );
        setSeatGroups(filteredSeats);
      } catch (error) {
        console.error("Error fetching seat data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [backendUrl, id]);

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setSelectedSeats([]);
    setQuantity(0);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    const availableSeatsCount = seatGroups.filter(
      (seat) => seat.groupnumber === selectedGroup && seat.is_available === "si"
    ).length;

    if (newQuantity > availableSeatsCount) {
      setQuantity(availableSeatsCount);
    } else {
      setQuantity(newQuantity);
    }

    setSelectedSeats([]);
  };

  const handleAddToCart = () => {
    let selected = [...selectedSeats];

    if (quantity > selectedSeats.length) {
      const availableSeats = seatGroups.filter(
        (seat) =>
          seat.groupnumber === selectedGroup && seat.is_available === "si"
      );
      const remaining = quantity - selectedSeats.length;
      for (let i = 0; i < remaining; i++) {
        const randomIndex = Math.floor(Math.random() * availableSeats.length);
        selected.push(availableSeats[randomIndex].id);
      }
    }

    onAsientosSeleccionadosChange(selected);
    setSelectedSeats([]);
    setQuantity(0);
  };

  const renderSeats = () => {
    return seatGroups
      .filter((seat) => seat.groupnumber === selectedGroup)
      .map((seat) => (
        <button
          key={seat.id}
          className={`p-2 rounded-t-md text-sm ${
            seat.is_available === "no"
              ? "bg-gray-500 cursor-not-allowed"
              : selectedSeats.includes(seat.id)
                ? "bg-purple-500 text-white"
                : "bg-gray-200"
          }`}
          onClick={() =>
            seat.is_available === "si" && handleSeatSelect(seat.id)
          }
          disabled={seat.is_available === "no"}
        >
          {seat.seat_number}
        </button>
      ));
  };

  const handleSeatSelect = (seatId) => {
    const isSelected = selectedSeats.includes(seatId);
    let updatedSelectedSeats;

    if (isSelected) {
      updatedSelectedSeats = selectedSeats.filter((id) => id !== seatId);
    } else {
      updatedSelectedSeats = [...selectedSeats, seatId];
    }

    setSelectedSeats(updatedSelectedSeats);
  };

  const seatGroupOptions = ["F", "G", "H", "I", "J"].map((group) => (
    <option translate="no" key={group} value={group}>
      {group}
    </option>
  ));

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col w-full">
        <div className="rounded-lg p-8 w-full shadow-lg">
          <div className="border-t-4 border-[#6200ee] mb-4" />
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <span className="text-3xl font-bold text-[#6200ee] mr-2">
                {selectedGroup}
              </span>
              <h2 className="text-2xl font-bold">Entradas VIP</h2>
              <FaShoppingCart className="ml-2 text-[#6200ee]" />
            </div>
            <div>
              <label className="block text-gray-700 font-medium" htmlFor="zone">
                Zona
              </label>
              <div className="relative">
                <select
                  translate="no"
                  className="w-14 h-7 border-2 border-gray-300 rounded-md focus:ring-opacity-50 bg-transparent"
                  id="zone"
                  onChange={(e) => handleGroupSelect(e.target.value)}
                  value={selectedGroup}
                >
                  {seatGroupOptions}
                </select>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-5 gap-2 mb-4">{renderSeats()}</div>
          <button
            className="w-full bg-[#6200ee] hover:bg-[#7c4dff] text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-[#6200ee] focus:ring-opacity-50"
            onClick={handleAddToCart}
          >
            Agregar al Carrito <FaCheckCircle className="inline ml-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Vip;

import React, { useState, useEffect } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import LoginButton from "./LoginButton";

const Permiso = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    setIsLoggedIn(!!userData);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userData");
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex"
          role="alert"
        >
          <FaExclamationCircle className="mr-2 text-red-500" />
          <div>¡Inicia sesión primero!</div>
        </div>
        <LoginButton />
      </div>
    );
  }

  return null;
};

export default Permiso;

import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./logout";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);

  if (isLoading) {
    return <div>Cargando ...</div>;
  }

  const handleToggleLogoutMenu = () => {
    setShowLogoutMenu(!showLogoutMenu);
  };

  return (
    isAuthenticated && (
      <div className="relative">
        <div
          className="flex items-center justify-end space-x-4"
          onClick={handleToggleLogoutMenu}
        >
          <img
            src={user.picture}
            alt={user.name}
            className="w-8 h-8 rounded-full cursor-pointer"
          />
          <h2 className="cursor-pointer">{user.name}</h2>
        </div>
        {showLogoutMenu && (
          <div className="absolute top-10 right-0 bg-white p-2 rounded shadow">
            <LogoutButton />
          </div>
        )}
      </div>
    )
  );
};

export default Profile;

import React from "react";
import GoogleLoginButton from "./google";

const LoginButton = ({ onClose }) => {
  const responseGoogleSuccess = (response) => {
    console.log("Inicio de sesión exitoso:", response);
    // Recargar la página después de iniciar sesión exitosamente
    window.location.reload();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 backdrop-blur-md z-50">
      <div className="flex shadow-md relative animate-bounce-fade-in">
        <button
          className="absolute top-0 right-0 m-4 bg-blue-500 hover:bg-blue-700 text-white rounded-full w-8 h-8 flex items-center justify-center"
          onClick={onClose}
        >
          <svg
            className="w-4 h-4 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <div
          className="flex flex-wrap content-center justify-center rounded-l-md bg-gray-100 text-black"
          style={{ width: "24rem", height: "32rem" }}
        >
          <div className="w-72">
            <h1 className="text-xl font-semibold text-gray-800">
              ¡Bienvenido de nuevo!
            </h1>
            <small className="text-gray-600">
              ¡Bienvenido de nuevo! Por favor, seleccione un método de sesión
            </small>

            <form className="mt-4">
              <div className="mb-3">
                <GoogleLoginButton onSuccess={responseGoogleSuccess} />
              </div>
            </form>
          </div>
        </div>

        <div
          className="hidden md:flex flex-wrap content-center justify-center rounded-r-md"
          style={{ width: "24rem", height: "32rem" }}
        >
          <img
            className="w-full h-full bg-center bg-no-repeat bg-cover rounded-r-md"
            src="https://i.ibb.co/2Yt2pBj/carta-de-logo.png"
            alt="Banner de inicio de sesión"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginButton;

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { gsap } from "gsap";
import { EnvironmentContext } from "../EnvironmentVariables";

const DropdownMenu = ({
  handleLogout,
  userData,
  show,
  isProfileMenuOpen,
  setIsProfileMenuOpen,
}) => {
  const { backendUrl } = useContext(EnvironmentContext);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchAdminStatus = async () => {
      try {
        const email = userData.email;
        const response = await axios.get(
          `${backendUrl}/admins.php?email=${encodeURIComponent(email)}`
        );
        const isAdmin = response.data.isAdmin;
        setIsAdmin(isAdmin);
      } catch (error) {
        console.error("Error al verificar estado de administrador:", error);
      }
    };

    fetchAdminStatus();
  }, [userData.email, backendUrl]); // Asegúrate de agregar backendUrl como dependencia

  const handleItemClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (!isProfileMenuOpen) {
      gsap.to(".box", { opacity: 0 });
    }
  }, [isProfileMenuOpen]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="absolute top-12 right-2 z-50"
        >
          <div
            className="bg-white divide-y divide-gray-300 rounded-lg shadow-lg"
            style={{ zIndex: 9999 }}
          >
            <div className="px-4 py-3 text-sm text-gray-800 flex items-center justify-between">
              <div className="font-medium">{userData.name}</div>
              {isAdmin && (
                <div className="text-green-800 bg-green-100 rounded px-2 py-1 text-xs font-semibold">
                  Admin
                </div>
              )}
            </div>
            <div className="px-4 py-1 text-xs text-gray-600">
              {userData.email}
            </div>
            <ul className="py-2 text-sm text-gray-800">
              <li onClick={handleItemClick}>
                <Link
                  to="/transacciones"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Compras de usuario
                </Link>
              </li>
              {isAdmin && (
                <>
                  <li onClick={handleItemClick}>
                    <Link
                      to="/dashboard"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      Panel de control
                    </Link>
                  </li>
                  <li onClick={handleItemClick}>
                    <Link
                      to="/entradas"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      Asistencia Eventos
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <div className="py-2">
              <button
                onClick={() => {
                  handleLogout();
                  window.location.reload(); // Recargar la página al cerrar sesión
                }}
                className="text-sm text-red-600 font-semibold hover:bg-gray-100 px-4 py-2 text-center"
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DropdownMenu;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { EnvironmentContext } from "../EnvironmentVariables";
import { FaSearch } from "react-icons/fa";
import Tarjetas from "./tarjetas";
import Oficialcard from "./Inicio//oficialcard";
import Footer from "./Inicio/footer";
import About from "./about";
import Hero from "./Inicio/hero";

const Home = () => {
  const [events, setEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputError, setInputError] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userName, setUserName] = useState("");
  const { backendUrl } = useContext(EnvironmentContext);
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerEventos = async () => {
      try {
        const respuesta = await fetch(`${backendUrl}/infoeventos.php`);
        if (!respuesta.ok) {
          throw new Error("Error al obtener los datos del servidor");
        }
        const datos = await respuesta.json();
        setEvents(datos);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    obtenerEventos();
  }, [backendUrl]);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("userData");
    if (userDataFromStorage) {
      const userData = JSON.parse(userDataFromStorage);
      setUserName(userData.name);
    }
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const foundEvent = events.find((event) =>
      event.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (foundEvent) {
      navigate(`/detalles/${foundEvent.id}`);
    } else {
      setInputError(true);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setInputError(false);
    if (value.trim() !== "") {
      generateSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const generateSuggestions = (value) => {
    const matchedEvents = events.filter((event) =>
      event.title.toLowerCase().includes(value.toLowerCase())
    );
    const topSuggestions = matchedEvents.slice(0, 3).map((event) => ({
      id: event.id,
      title: event.title,
    }));
    setSuggestions(topSuggestions);
  };

  const handleSuggestionClick = (eventId) => {
    navigate(`/detalles/${eventId}`);
    setSearchQuery("");
    setSuggestions([]);
  };

  return (
    <div className="text-center text-black">
      <Navbar />

      <form onSubmit={handleSearch} className="mx-auto max-w-md px-4 relative">
        <p className="">BIENVENIDOS A EVENTOS</p>
        <p translate="no" className=" text-xs mb-2 text-gray-500">
          CCI CONDAL BCN
        </p>
        <div className="relative flex items-center">
          <input
            type="text"
            placeholder={
              inputError
                ? "No se pudo encontrar el evento"
                : "Buscar evento por título"
            }
            value={searchQuery}
            onChange={handleInputChange}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setShowSuggestions(false)}
            className={`w-full p-3 pr-10 rounded-lg bg-gray-200 border-2 ${
              inputError ? "border-red-500" : ""
            } focus:outline-none focus:ring-2 focus:ring-blue-500 z-10`}
            style={{ backgroundColor: "#f0f4f8" }} // Fondo gris con un toque azul
          />
          <FaSearch className="absolute right-3 transform -translate-y-1/2 top-1/2 text-gray-500 z-40" />
        </div>
        {showSuggestions && suggestions.length > 0 && (
          <ul
            className="absolute left-5 right-0 mt-1 rounded-b-lg overflow-y-auto z-20"
            style={{
              backgroundColor: "#f0f4f8",
              width: "92%",
              borderTop: "none",
              border: "2px solid #d1d5db",
              borderTopWidth: 0,
            }}
          >
            {suggestions.map((event) => (
              <li
                key={event.id}
                className="cursor-pointer py-2 px-3 hover:bg-purple-100"
                onMouseDown={() => handleSuggestionClick(event.id)}
              >
                {event.title}
              </li>
            ))}
          </ul>
        )}
      </form>

      <About />
      <Oficialcard />
      <Tarjetas />
      <Footer />
    </div>
  );
};

export default Home;

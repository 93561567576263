import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { EnvironmentContext } from "../EnvironmentVariables";
import QRCode from "qrcode.react";
import html2pdf from "html2pdf.js";
import logo from "../img/logo.png";

const Ticket = () => {
  const { id } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [event, setEvent] = useState(null);
  const [zone, setZone] = useState(null);
  const { backendUrl } = useContext(EnvironmentContext);
  const ticketRef = useRef(null);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const transactionResponse = await fetch(
          `${backendUrl}/transacciones.php?id=${id}`
        );
        if (!transactionResponse.ok) {
          throw new Error("Error al obtener los datos de la transacción");
        }
        const transactionData = await transactionResponse.json();
        setTransaction(transactionData);

        const eventId = transactionData.eventId;

        const eventResponse = await fetch(
          `${backendUrl}/infoeventos.php?eventId=${eventId}`
        );
        if (!eventResponse.ok) {
          throw new Error("Error al obtener los datos del evento");
        }
        const eventData = await eventResponse.json();
        setEvent(eventData);

        const seatId = transactionData.seat_id;
        const zoneResponse = await fetch(
          `${backendUrl}/zona.php?seatId=${seatId}`
        );
        if (!zoneResponse.ok) {
          throw new Error("Error al obtener la zona del asiento");
        }
        const zoneData = await zoneResponse.json();
        setZone(zoneData.zone);
      } catch (error) {
        console.error("Error al obtener la transacción:", error);
      }
    };

    fetchTransaction();
  }, [backendUrl, id]);

  const handleDownloadPDF = () => {
    const opt = {
      margin: 1,
      filename: "ticket.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    const downloadButton = document.getElementById("downloadButton");
    if (downloadButton) {
      downloadButton.style.display = "none";
    }

    html2pdf()
      .from(ticketRef.current)
      .set(opt)
      .save()
      .then(() => {
        if (downloadButton) {
          downloadButton.style.display = "block";
        }
      });
  };

  if (!transaction || !event || !zone) {
    return <div>Cargando...</div>;
  }

  const qrParams = `id=${transaction.id}&amount=${transaction.amount}&eventId=${transaction.eventId}&timestamp=${transaction.timestamp}&username=${transaction.username}&email=${transaction.email}`;
  const eventDate = new Date(transaction.timestamp);
  const eventTime = eventDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
      <button
        id="downloadButton"
        className="mt-4 mb-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleDownloadPDF}
        style={{ position: "absolute", bottom: "20px", zIndex: "999" }}
      >
        Descargar PDF
      </button>
      <div
        ref={ticketRef}
        className="relative w-[400px] min-h-[750px] bg-white rounded-md shadow-lg"
      >
        <img
          src={event.image_url}
          alt="Evento"
          className="absolute top-0 w-full h-[200px] rounded-t-md object-cover z-0"
        />
        <div className="absolute top-[200px] w-full h-[340px] bg-black rounded-b px-6 pt-8 flex flex-col justify-between z-10">
          <div>
            <div className="text-white text-2xl font-semibold mb-2 mt-10 text-center">
              {event.title}
            </div>
            <div className="flex justify-between mb-4">
              <div className="text-white text-sm">
                {eventDate.toLocaleDateString("es-ES", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                })}
              </div>
              <div className="text-white text-sm">{transaction.amount}€</div>
            </div>

            <div className="text-white text-lg font-light mb-2 text-center"></div>
            {transaction.type === "vip" ? (
              <div className="text-white text-6xl font-bold italic mb-4 text-center">
                {transaction.seat_number}
              </div>
            ) : null}
            <div className="text-white text-lg font-light mb-4 text-center">
              Zona: {zone}
            </div>
          </div>
        </div>
        <div className="absolute top-[540px] w-full h-[200px] bg-gray-200 rounded-b-md px-6 pt-4 flex flex-col items-center z-10">
          <QRCode value={qrParams} size={120} />
          <div className="text-gray-700 text-sm mt-2">
            Email: {transaction.email}
          </div>
          <div className="text-gray-700 text-sm">ID: {transaction.id}</div>
        </div>
        <img
          src={logo}
          alt="Logo"
          className="absolute top-[140px] w-32 h-32 rounded-full bg-black z-10"
          style={{ left: "calc(50% - 62px)" }}
        />
      </div>
    </div>
  );
};

export default Ticket;

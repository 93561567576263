import React, { useState, useEffect } from "react";
import SeleccionEventos from "./selecion";
import ConfiguracionEvento from "./configeventos";

const ListaEventos = () => {
  const [eventoSeleccionado, setEventoSeleccionado] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleEventoSeleccionado = (evento) => {
    setEventoSeleccionado(evento);
  };

  const handleEventoActualizado = () => {
    setEventoSeleccionado(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Cuando la carga está completa, cambiar isLoading a false
    }, 200);

    // Limpiar el temporizador si el componente se desmonta antes de que se complete la carga
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative">
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center bg-white bg-opacity-50">
          <div className="loadingspinner"></div>
        </div>
      )}
      <div
        className={isLoading ? "opacity-0" : "opacity-100 transition-opacity"}
      >
        <div className="flex justify-center">
          <SeleccionEventos onEventoSeleccionado={handleEventoSeleccionado} />
          {eventoSeleccionado && (
            <ConfiguracionEvento
              evento={eventoSeleccionado}
              onEventoActualizado={handleEventoActualizado}
            />
          )}
        </div>
        <div className="bg-gray-200 h-full text-center py-4">
          Este módulo es solo para edición previa a la publicación del evento.
          Es un paso previo a la creación de uno finalizado.
        </div>
      </div>
    </div>
  );
};

export default ListaEventos;

import React, { useState, useContext } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { EnvironmentContext } from "../EnvironmentVariables";

const Formulario = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    participants: "",
    date: "",
    time: "",
    cost: "",
    vip: false, // Agregado para seleccionar si es VIP
    costvip: "", // Precio VIP
    image_url: "",
  });

  const { backendUrl } = useContext(EnvironmentContext);

  const [redirect, setRedirect] = useState(false); // Estado para la redirección

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Agregar el campo "state" con el valor "apagado" al enviar
      const dataToSend = { ...formData, state: "apagado" };

      // Enviar datos al servidor
      await axios.post(`${backendUrl}/submit.php`, dataToSend);

      // Establecer el estado de redirección a true
      setRedirect(true);
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value, // Manejo del cambio para checkboxes
    });
  };

  // Si el estado de redirección es true, redirige a /dashboard/event-list
  if (redirect) {
    return <Navigate to="/dashboard/event-list" />;
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <form
        onSubmit={handleSubmit}
        className="max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Título
          </label>
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Título"
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            Descripción
          </label>
          <textarea
            name="description"
            id="description"
            placeholder="Descripción"
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          ></textarea>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="participants"
          >
            Organizadores
          </label>
          <input
            type="text"
            name="participants"
            id="participants"
            placeholder="Organizadores"
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="image_url"
          >
            URL de la imagen
          </label>
          <input
            type="text"
            name="image_url"
            id="image_url"
            placeholder="URL de la imagen"
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Formulario;

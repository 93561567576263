// ListaEventos.js
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Select, SelectItem } from "@tremor/react";
import { EnvironmentContext } from "../../EnvironmentVariables";
import Formulario from "./formulario"; // Importa el componente Formulario

const ListaEventos = () => {
  const [eventos, setEventos] = useState([]);
  const [eventoSeleccionado, setEventoSeleccionado] = useState("");
  const { backendUrl } = useContext(EnvironmentContext);
  const [mostrarFormulario, setMostrarFormulario] = useState(false); // Estado para controlar la visibilidad del formulario
  const [eventoId, setEventoId] = useState(""); // Estado para almacenar el ID del evento seleccionado

  useEffect(() => {
    cargarEventos();
  }, []);

  const cargarEventos = async () => {
    try {
      const response = await axios.get(`${backendUrl}/infoeventos.php`);
      const eventosActivos = response.data.filter(
        (evento) => evento.state === "activo"
      );
      setEventos(eventosActivos);
    } catch (error) {
      console.error("Error al cargar eventos:", error);
    }
  };

  const handleEventoSeleccionado = (eventoId) => {
    setEventoSeleccionado(eventoId);
    // Mostrar el formulario al seleccionar un evento
    setMostrarFormulario(true);
    // Establecer el ID del evento seleccionado
    setEventoId(eventoId);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {!mostrarFormulario && ( // Renderizar solo si el formulario no está mostrándose
        <>
          <p className="mb-4 text-center">
            Seleccione el evento para registrar las asistencias
          </p>
          <div className="mx-auto max-w-xs">
            <Select
              id="evento"
              name="evento"
              value={eventoSeleccionado}
              onValueChange={handleEventoSeleccionado}
              className="mt-2"
            >
              {eventos.map((evento) => (
                <SelectItem key={evento.id} value={evento.id}>
                  {evento.title}
                </SelectItem>
              ))}
            </Select>
          </div>
        </>
      )}
      {mostrarFormulario && (
        <Formulario
          eventoId={eventoId}
          tituloEvento={
            eventos.find((evento) => evento.id === eventoId)?.title || ""
          }
        />
      )}
    </div>
  );
};

export default ListaEventos;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EnvironmentContext } from "../../EnvironmentVariables";
import { Card, List, ListItem, Title } from "@tremor/react";

const Selector = ({ onEventoSeleccionado }) => {
  const [eventos, setEventos] = useState([]);
  const [selectorVisible, setSelectorVisible] = useState(true);
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    cargarEventos();
  }, []);

  const cargarEventos = async () => {
    try {
      const response = await axios.get(`${backendUrl}/infoeventos.php`);
      setEventos(response.data);
    } catch (error) {
      console.error("Error al cargar eventos:", error);
    }
  };

  const handleEventoSeleccionado = (eventoId, costo, costoVip) => {
    onEventoSeleccionado(eventoId, costo, costoVip);
    setSelectorVisible(false); // Oculta el selector después de seleccionar un evento
  };

  return (
    selectorVisible && (
      <div className="w-[200px] ">
        <Title className="font-medium">
          Seleccione Uno De Los Eventos Vigentes
        </Title>
        <List className="mt-2">
          {eventos.map((evento) => (
            <ListItem
              key={evento.id}
              onClick={() =>
                handleEventoSeleccionado(evento.id, evento.cost, evento.costvip)
              }
              className="cursor-pointer hover:text-blue-500"
            >
              <span>{evento.title}</span>
            </ListItem>
          ))}
        </List>
      </div>
    )
  );
};

export default Selector;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EnvironmentContext } from "../../EnvironmentVariables";
import { v4 as uuidv4 } from "uuid";

const Gratis = ({ seatDetails, eventId, onSuccess, onError }) => {
  const { backendUrl } = useContext(EnvironmentContext);
  const [sessionData, setSessionData] = useState(null);

  useEffect(() => {
    // Obtener datos del usuario del almacenamiento local al montar el componente
    const userDataFromStorage = localStorage.getItem("userData");
    if (userDataFromStorage) {
      const userData = JSON.parse(userDataFromStorage);
      setSessionData(userData);
    }
  }, []);

  const handleGetSeat = async () => {
    try {
      if (!sessionData) {
        onError("No se encontraron datos de sesión local.");
        return;
      }

      const { name, email } = sessionData;

      // Generar un token único
      const token = uuidv4();

      // Preparar los datos para enviar al servidor
      const requestData = {
        username: name,
        email: email,
        eventId: eventId,
        seats: seatDetails.map((seat) => ({
          seat_number: seat.seat_number,
          seat_id: seat.id,
          timestamp: new Date().toISOString(),
          token: token,
          amount: "",
        })),
      };

      // Mostrar el objeto requestData en la consola antes de enviarlo al servidor
      console.log("Datos enviados al servidor:", requestData);

      // Enviar datos a gratis.php utilizando la variable de entorno backendUrl
      const response = await axios.post(
        `${backendUrl}/gratis.php`,
        requestData
      );

      console.log("Respuesta del servidor:", response.data);

      // Llamar a la función onSuccess para realizar acciones posteriores al éxito
      onSuccess();

      // Recargar la página después de completar la función
      window.location.reload();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <button
      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4 w-full"
      onClick={handleGetSeat}
    >
      Obtener Asiento
    </button>
  );
};

export default Gratis;

import React, { useState } from "react";
import ListaEventos from "./listaeventos";

const Asistencia = () => {
  const [eventoSeleccionado, setEventoSeleccionado] = useState(null);

  const handleEventoSeleccionado = (eventoId) => {
    setEventoSeleccionado(eventoId);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {!eventoSeleccionado && (
        <ListaEventos onEventoSeleccionado={handleEventoSeleccionado} />
      )}
    </div>
  );
};

export default Asistencia;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EnvironmentContext } from "../EnvironmentVariables";

const SeleccionEventos = ({ onEventoSeleccionado }) => {
  const [eventos, setEventos] = useState([]);
  const [eventoSeleccionado, setEventoSeleccionado] = useState(null);

  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    cargarEventos();
  }, []);

  useEffect(() => {
    if (eventos.length > 0 && !eventoSeleccionado) {
      onEventoSeleccionado(eventos[0]); // Emitir el primer evento seleccionado
    }
  }, [eventos, eventoSeleccionado, onEventoSeleccionado]);

  const cargarEventos = async () => {
    try {
      const response = await axios.get(`${backendUrl}/infoeventos.php`);

      const eventosFiltrados = response.data.filter(
        (evento) => evento.state === "apagado" // Filtrar solo los eventos con estado "apagado"
      );
      setEventos(eventosFiltrados);
    } catch (error) {
      console.error("Error al cargar eventos:", error);
    }
  };

  const handleEventoSeleccionado = (evento) => {
    if (eventoSeleccionado === evento) {
      // Si el evento seleccionado es el mismo que el actualmente seleccionado, anula la selección
      setEventoSeleccionado(null);
    } else {
      // Si se selecciona un nuevo evento, lo establece como seleccionado
      setEventoSeleccionado(evento);
      onEventoSeleccionado(evento);
    }
  };

  return (
    <div
      className="flex flex-col flex-grow w-full mr-10 p-10 bg-white shadow-md overflow-y-auto max-w-screen-lg mx-auto"
      style={{
        maxHeight: "900px",
        borderRadius: "6px",
        scrollbarWidth: "thin",
        scrollbarColor: "rgba(155, 155, 155, 0.5) transparent", // Color de la barra de desplazamiento
      }}
    >
      <h2 className="text-lg font-semibold mb-4">Eventos</h2>
      {eventos.length === 0 ? (
        <div className="text-gray-500">No hay eventos apagados</div>
      ) : (
        eventos.map((evento) => (
          <div
            key={evento.id}
            className={`rounded-lg p-4 mb-4 cursor-pointer border border-gray-300 ${
              evento === eventoSeleccionado
                ? "bg-blue-500 text-white "
                : "hover:bg-gray-200"
            }`}
            onClick={() => handleEventoSeleccionado(evento)}
          >
            <h3 className="text-md font-semibold mb-2">{evento.title}</h3>
            <p className="text-sm text-blue-300">{evento.description}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default SeleccionEventos;

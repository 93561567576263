import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom"; // Usar useNavigate para la navegación
import FormularioEvento from "./formularioeventos";
import { EnvironmentContext } from "../EnvironmentVariables";

const ConfiguracionEvento = ({ evento, onEventoActualizado }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    participants: "",
    date: "",
    time: "",
    cost: "",
    vip: false,
    costvip: "",
    image_url: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Usar useNavigate para la navegación

  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    if (evento) {
      setFormData({
        title: evento.title,
        description: evento.description,
        participants: evento.participants,
        date: evento.date,
        time: evento.time,
        cost: evento.cost,
        vip: evento.vip === "1",
        costvip: evento.costvip,
        image_url: evento.image_url,
      });
    }
  }, [evento]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${backendUrl}/actualizar.php`, {
        id: evento.id,
        ...formData,
        vip: formData.vip ? "si" : "no", // Convertir booleano a "si" o "no"
      });
      console.log(response.data);
      onEventoActualizado();
      setLoading(false);
      mostrarAlerta().then(() => {
        navigate("/dashboard/event-configuration");
      });
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const mostrarAlerta = () => {
    return Swal.fire({
      icon: "success",
      title: "Datos configurados correctamente",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div className="w-full mx-auto">
      {loading && <div className="text-center">Cargando...</div>}
      <FormularioEvento
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ConfiguracionEvento;

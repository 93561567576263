import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
// Asumiendo la importación de tus componentes aquí.
import ListaEventos from "./listaeventos";
import Formulario from "./form";
import Nav1 from "./opcionesnav/nav1";
import EventConfigurationWizard from "./asientos/EventConfigurationWizard";
import Activareventos from "./activareventos";
import VipSeatConfiguration from "./asientos/VipSeatConfiguration";
import Acceso from "../log/Acceso";
import Data from "./data";
import ConfigOn from "./configuracion/ConfigOn";
import Desactivar from "./desactivar";
import Asistencia from "./asistenciaeventos/Asistencia";
import Comprar from "./opcionesnav/comprar";

export const DashboardRoutes = () => (
  <Routes>
    <Route path="/event-list" element={<ListaEventos />} />
    <Route path="/event-creation" element={<Formulario />} />
    <Route path="/event-configuration" element={<EventConfigurationWizard />} />
    <Route path="/event_activate" element={<Activareventos />} />
    <Route path="/event-admin" element={<ConfigOn />} />
    <Route path="/event-desactivate" element={<Desactivar />} />
    <Route path="/event-assistance" element={<Asistencia />} />
    <Route path="/event-compra" element={<Comprar />} />
    <Route
      path="/vip-seat-configuration/:Id"
      element={<VipSeatConfiguration />}
    />
    <Route path="/" element={<Data />} />
  </Routes>
);

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="flex min-h-screen flex-col bg-gray-100 text-gray-800">
      <Acceso />
      <div className="flex flex-1">
        <div
          className={`sidebar ${sidebarOpen ? "open" : ""} bg-white text-gray-800`}
        >
          <Nav1 />
        </div>
        <div className="flex-1 w-full p-4">
          <button className="toggle-btn" onClick={toggleSidebar}>
            <i className="fas fa-bars"></i>
          </button>
          <div className="flex-1">
            <DashboardRoutes />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

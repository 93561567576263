import React, { useState, useEffect } from "react";
import { FaTicketAlt } from "react-icons/fa";

const About = () => {
  const texts = [
    "Consigue tus entradas para tus eventos favoritos.",
    "Disfruta de una gran experiencia.",
    "Compra tus entradas de forma segura.",
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="max-w-xl mx-auto relative mt-3 ml-4 mr-4 md:hidden">
      <div className="w-full h-[70px] bg-black rounded-lg flex items-center justify-between px-4 py-2 relative">
        <div className="absolute left-2 top-2">
          <div className="h-1 w-1 bg-green-500 rounded-full"></div>
        </div>
        <div className="flex items-center">
          <div className="text-white text-xs font-normal text-left leading-tight max-w-[75%] pl-4 pr-6 relative">
            {texts[currentTextIndex]}
          </div>
          <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
            <div className="h-full w-1 bg-white"></div>
            <FaTicketAlt
              className="h-12 w-12 text-white ml-3"
              style={{ zIndex: 10 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { EnvironmentContext } from "../../EnvironmentVariables";

const EventSelection = ({ onNextStep }) => {
  const [eventData, setEventData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const navigate = useNavigate();
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/infoeventos.php`);
        // Filtrar los eventos para mostrar solo aquellos con state: "apagado"
        const filteredEvents = response.data.filter(
          (event) => event.state === "apagado"
        );
        setEventData(filteredEvents);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, []);

  const handleEventSelect = (eventId) => {
    onNextStep(eventId); // Llama a la función onNextStep pasándole la ID del evento seleccionado
    navigate(`/dashboard/event-configuration?eventId=${eventId}`);
  };

  return (
    <div
      className="flex justify-center items-center h-screen relative"
      onMouseOver={() => setScrollEnabled(true)}
      onMouseOut={() => setScrollEnabled(false)}
      onWheel={(e) => {
        if (scrollEnabled) {
          if (e.deltaY > 0) {
            setStartIndex((prevIndex) => (prevIndex + 1) % eventData.length);
          } else {
            setStartIndex((prevIndex) =>
              prevIndex === 0 ? eventData.length - 1 : prevIndex - 1
            );
          }
        }
      }}
    >
      {!eventData.length ? null : (
        <>
          <p className="absolute top-10 text-gray-500">
            Seleccione un evento para configurar los asientos, puede usar el
            scroll o rueda del raton para desplazar los eventos.
          </p>
          <div
            className="relative flex flex-row space-x-10 cursor-pointer"
            ref={containerRef}
          >
            {eventData.slice(startIndex, startIndex + 3).map((event, index) => (
              <div
                key={event.id}
                className={`relative w-80 h-96 border border-gray-200 rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-110 ${
                  index === 1 ? "scale-110" : ""
                }`}
                onClick={() => handleEventSelect(event.id)}
              >
                <img
                  src={event.image_url}
                  alt={event.title}
                  className="w-full h-3/6 object-cover"
                />
                <div className="w-full p-5">
                  <h1 className="text-sm">{event.title}</h1>
                  <hr />
                  <p className="text-xs mt-6">{event.description}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default EventSelection;

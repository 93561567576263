import React from "react";

const FormularioEvento = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded-lg p-4 w-full h-full"
    >
      <div className="mb-4">
        <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
          Título:
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={formData.title}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Título"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="description"
          className="block text-gray-700 font-bold mb-2"
        >
          Descripción:
        </label>
        <textarea
          name="description"
          id="description"
          value={formData.description}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 h-12 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Descripción"
        ></textarea>
      </div>
      <div className="mb-4">
        <label
          htmlFor="participants"
          className="block text-gray-700 font-bold mb-2"
        >
          Participantes:
        </label>
        <input
          type="text"
          name="participants"
          id="participants"
          value={formData.participants}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Participantes"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="date" className="block text-gray-700 font-bold mb-2">
          Fecha:
        </label>
        <input
          type="date"
          name="date"
          id="date"
          value={formData.date}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="time" className="block text-gray-700 font-bold mb-2">
          Hora:
        </label>
        <input
          type="time"
          name="time"
          id="time"
          value={formData.time}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="cost" className="block text-gray-700 font-bold mb-2">
          Costo:
        </label>
        <input
          type="text"
          name="cost"
          id="cost"
          value={formData.cost}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Costo"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="vip" className="block text-gray-700 font-bold mb-2">
          VIP:
        </label>
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            name="vip"
            id="vip"
            checked={formData.vip}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <span className="ml-2 text-gray-700">VIP</span>
        </label>
      </div>
      {formData.vip && (
        <div className="mb-4">
          <label
            htmlFor="costvip"
            className="block text-gray-700 font-bold mb-2"
          >
            Costo VIP:
          </label>
          <input
            type="text"
            name="costvip"
            id="costvip"
            value={formData.costvip}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Costo VIP"
          />
        </div>
      )}
      <div className="mb-4">
        <label
          htmlFor="image_url"
          className="block text-gray-700 font-bold mb-2"
        >
          URL de la Imagen:
        </label>
        <input
          type="text"
          name="image_url"
          id="image_url"
          value={formData.image_url}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="URL de la Imagen"
        />
      </div>
      <button
        type="submit" // Cambiado de type="button" a type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
      >
        Guardar
      </button>
    </form>
  );
};

export default FormularioEvento;

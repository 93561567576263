import React, { useState, useEffect, useCallback, useContext } from "react";
import { AreaChart, Icon } from "@tremor/react";
import { RiCashFill } from "@remixicon/react";
import axios from "axios";
import { EnvironmentContext } from "../../EnvironmentVariables";

const valueFormatter = function (number) {
  return new Intl.NumberFormat("Eur").format(number).toString();
};

export function Ganancias() {
  const { backendUrl } = useContext(EnvironmentContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalMes, setTotalMes] = useState(0); // Estado para almacenar el total del mes

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrl}/stats/mes.php`);
      setData(response.data);
      setLoading(false);

      // Calcular el total del mes al recibir los datos
      const total = response.data.reduce(
        (acc, curr) => acc + parseFloat(curr.ganancias),
        0
      );
      setTotalMes(total);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, [backendUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="border rounded w-[450px] p-4">
      <div className="flex items-center mb-4">
        <h3 className="text-gray-600 font-semibold text-lg mr-2">
          Total del mes
        </h3>
        <Icon icon={RiCashFill} color="green" size="lg" />
      </div>

      <p className="text-blue-600 font-bold text-xl mb-4">
        {valueFormatter(totalMes)} €
      </p>

      <hr className="my-4" />

      <h4 className="text-gray-600 font-semibold text-lg mb-2">
        Ganancias por semana
      </h4>

      {loading ? (
        <p>Cargando...</p>
      ) : (
        <AreaChart
          className="h-56 w-[400px] mt-2"
          index="semana"
          data={data}
          yAxisWidth={65}
          categories={["ganancias"]}
          colors={["green"]}
          valueFormatter={valueFormatter}
        />
      )}
    </div>
  );
}

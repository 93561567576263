import React, { useState, useContext } from "react";
import { TextInput } from "@tremor/react";
import { EnvironmentContext } from "../../EnvironmentVariables";

const Formulario = ({
  asientoSeleccionado,
  precioAsiento,
  zonaAsiento,
  tipoAsiento,
}) => {
  const { backendUrl } = useContext(EnvironmentContext);
  const event_id = asientoSeleccionado ? asientoSeleccionado.event_id : null;
  const seat_number = asientoSeleccionado
    ? asientoSeleccionado.seat_number
    : null;
  const fechaHora = new Date().toISOString();
  const [usuario, setUsuario] = useState("");
  const [correo, setCorreo] = useState("");
  const [errorUsuario, setErrorUsuario] = useState("");
  const [errorCorreo, setErrorCorreo] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [tipoMensaje, setTipoMensaje] = useState(""); // 'exito' o 'error'

  const handleGuardarTransaccion = async () => {
    // Validación de campos
    if (!usuario) {
      setErrorUsuario("¡El nombre de usuario es obligatorio!");
    } else {
      setErrorUsuario("");
    }
    if (!correo) {
      setErrorCorreo("¡El correo electrónico es obligatorio!");
    } else {
      setErrorCorreo("");
    }

    if (!usuario || !correo) {
      return;
    }

    // Creación del objeto de la nueva transacción
    const nuevaTransaccion = {
      username: usuario,
      email: correo,
      seat_number: seat_number,
      event_id: event_id,
      seat_id: asientoSeleccionado.id,
      amount: precioAsiento,
      timestamp: fechaHora,
      zona: zonaAsiento,
      tipo: tipoAsiento,
    };

    // Envío de la transacción al servidor
    try {
      const response = await fetch(`${backendUrl}/transaccionesadmin.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nuevaTransaccion),
      });

      if (response.ok) {
        const data = await response.json();
        setMensaje("¡Guardado exitosamente!");
        setTipoMensaje("exito");

        setUsuario("");
        setCorreo("");
      } else {
        setMensaje("Error al guardar la transacción.");
        setTipoMensaje("error");
      }
    } catch (error) {
      setMensaje("Error al enviar la transacción.");
      setTipoMensaje("error");
    }
  };

  return (
    <div className="flex flex-col w-full mb-8">
      <h2 className="text-lg font-bold mb-4 text-center">
        Formulario de Transacciones
      </h2>
      <div className="flex flex-col space-y-4 mx-auto max-w-lg w-full">
        <TextInput
          className="w-full border-b border-gray-200"
          placeholder="Nombre de Usuario"
          value={usuario}
          onChange={(e) => {
            setUsuario(e.target.value);
            setErrorUsuario("");
          }}
        />
        {errorUsuario && <p className="text-red-500">{errorUsuario}</p>}

        <TextInput
          className="w-full border-b border-gray-200"
          placeholder="Correo Electrónico"
          value={correo}
          onChange={(e) => {
            setCorreo(e.target.value);
            setErrorCorreo("");
          }}
        />
        {errorCorreo && <p className="text-red-500">{errorCorreo}</p>}

        <TextInput
          className="w-full border-b border-gray-200"
          placeholder="Nombre de Asiento"
          value={seat_number}
          disabled
        />

        {/* Campos ocultos */}
        <input type="hidden" value={fechaHora} />
        <input type="hidden" value={event_id} />
        <input type="hidden" value={zonaAsiento || ""} />
        <input type="hidden" value={tipoAsiento || ""} />

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          onClick={handleGuardarTransaccion}
        >
          Guardar Transacción
        </button>
        {mensaje && (
          <p
            className={`p-4 ${
              tipoMensaje === "exito" ? "text-green-500" : "text-red-500"
            }`}
          >
            {mensaje}
          </p>
        )}
      </div>
    </div>
  );
};

export default Formulario;

import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { EnvironmentContext } from "../EnvironmentVariables";
import axios from "axios";
import { HiArrowLeft } from "react-icons/hi";
import { FiFacebook, FiInstagram, FiClock } from "react-icons/fi";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@tremor/react";

const Detalles = () => {
  const { id } = useParams();
  const { backendUrl } = useContext(EnvironmentContext);
  const [evento, setEvento] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contador, setContador] = useState("");

  useEffect(() => {
    const obtenerEvento = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/infoeventos.php?eventId=${id}`
        );
        setEvento(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener detalles del evento:", error);
        setLoading(false);
      }
    };

    obtenerEvento();
  }, [backendUrl, id]);

  useEffect(() => {
    const calcularTiempoRestante = () => {
      if (evento && evento.fecha_final) {
        const fechaEvento = new Date(
          evento.fecha_final + "T00:00:00+02:00"
        ).getTime();
        const ahora = new Date().getTime();
        const diferencia = fechaEvento - ahora;

        if (diferencia < 0) {
          setContador("El evento ha finalizado");
          return;
        }

        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
        const horas = Math.floor(
          (diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutos = Math.floor(
          (diferencia % (1000 * 60 * 60)) / (1000 * 60)
        );
        const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

        setContador(`${dias}d ${horas}h ${minutos}m ${segundos}s`);
      }
    };

    let intervalo = setInterval(calcularTiempoRestante, 1000);

    return () => clearInterval(intervalo);
  }, [evento]);

  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const formattedHour =
      hourInt > 12 ? hourInt - 12 : hourInt === 0 ? 12 : hourInt;
    const ampm = hourInt >= 12 ? "PM" : "AM";
    return `${formattedHour}:${minute} ${ampm}`;
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr + "T00:00:00+02:00");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  return (
    <div className="bg-white min-h-screen pt-12 p-4 md:p-0">
      <div className="px-6">
        <Link
          to="/"
          className="absolute top-4 left-4 flex items-center py-2 px-3 border border-black rounded transition text-black z-10 hover:bg-gray-200"
        >
          <HiArrowLeft className="mr-2" />
        </Link>
      </div>
      <div className="max-w-5xl mx-auto pb-12">
        <div className="relative w-full h-64 md:h-96 overflow-hidden rounded-lg shadow-lg">
          <img
            alt={loading ? "" : evento ? evento.title : ""}
            className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 transform hover:scale-105"
            src={loading ? "/placeholder.svg" : evento ? evento.image_url : ""}
          />
          <div className="absolute bottom-0 left-0 w-full flex justify-center pb-4 bg-gradient-to-t from-black via-transparent to-transparent opacity-75">
            <p className="text-lg font-bold text-white">
              {loading
                ? ""
                : evento
                  ? formatDate(evento.date)
                  : "Fecha no disponible"}
            </p>
          </div>
        </div>
        <h1 className="text-2xl font-bold leading-tight text-center my-4">
          {loading
            ? "Cargando..."
            : evento
              ? evento.title
              : "No se encontró información para este evento."}
        </h1>
        <p className="text-gray-500 text-center mb-4">
          {loading
            ? ""
            : evento
              ? `Organizado por: ${evento.participants}`
              : ""}
        </p>
        <div className="flex flex-col items-center mb-8">
          <p translate="no" className="text-2xl font-bold mt-4">
            {contador}
          </p>
        </div>
        <div className="flex justify-between items-center mb-4 px-6">
          <div className="flex items-center">
            <FiClock className="text-black mr-2" />
            <p className="text-black">
              {loading ? "" : evento ? formatTime(evento.time) : ""}
            </p>
          </div>
          <div className="flex items-center">
            <a
              href={loading ? "" : evento ? evento.instagram : ""}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <FiInstagram className="text-black cursor-pointer transition-transform duration-200 transform hover:scale-110" />
            </a>
            <a
              href={loading ? "" : evento ? evento.facebook : ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiFacebook className="text-black cursor-pointer transition-transform duration-200 transform hover:scale-110" />
            </a>
          </div>
        </div>
        <TabGroup>
          <TabList
            variant="line"
            className="border-b border-gray-200 flex justify-center mb-4"
          >
            <Tab value="1" className="hover:bg-transparent">
              Evento
            </Tab>
            <Tab value="2" className="hover:bg-transparent">
              Descripción
            </Tab>
            <Tab value="3" className="hover:bg-transparent">
              Biografía
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel value="1">
              <div className="flex flex-wrap justify-center gap-4 text-center">
                <div className="w-full">
                  <div className="flex justify-center w-full">
                    <div className="bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded relative mr-2 w-32 shadow-lg transition-transform duration-200 transform hover:scale-105">
                      <p className="text-sm">Precio Normal</p>
                      <p className="text-lg font-bold">
                        {loading ? "" : evento ? `${evento.cost}€` : ""}
                      </p>
                    </div>{" "}
                    <div className="bg-purple-100 border border-purple-500 text-purple-700 px-4 py-3 rounded relative w-32 shadow-lg transition-transform duration-200 transform hover:scale-105">
                      <p className="text-sm">VIP</p>
                      <p className="text-lg font-bold">
                        {loading ? "" : evento ? `${evento.costvip}€` : ""}
                      </p>
                    </div>
                  </div>
                  {!loading && evento && evento.state === "activo" && (
                    <Link to={`/compra/${id}`}>
                      <button className="mt-4 bg-purple-700 hover:bg-purple-800 px-6 py-3 text-white font-bold rounded-md shadow-lg transition duration-300 ease-in-out w-full max-w-xs">
                        Comprar
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="space-y-4 px-4">
                <p className="text-sm text-justify">
                  {loading ? "" : evento ? evento.description : ""}
                </p>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="space-y-4 px-4">
                <p className="text-sm text-justify">
                  {loading ? "" : evento ? evento.biografia : ""}
                </p>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default Detalles;

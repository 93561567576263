import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { EnvironmentContext } from "../../EnvironmentVariables";

const VipSeatConfiguration = () => {
  const [loading, setLoading] = useState(true);
  const [seatGroups, setSeatGroups] = useState({});
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [selectedSeats, setSelectedSeats] = useState(new Set());
  const [serverMessage, setServerMessage] = useState("");
  const { Id } = useParams(); // Obtener el eventId de los parámetros de la URL

  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    console.log("Id del evento:", Id);

    const fetchSeatGroups = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/asientos.php?eventId=${Id}`
        );
        if (response.data && Array.isArray(response.data)) {
          const filteredSeats = response.data.filter(
            (seat) => seat.event_id === Id
          );
          const groupedSeats = filteredSeats.reduce((groups, seat) => {
            const groupNumber = seat.groupnumber;
            if (!groups[groupNumber]) {
              groups[groupNumber] = [];
            }
            groups[groupNumber].push(seat);
            return groups;
          }, {});
          setSeatGroups(groupedSeats);
        } else {
          console.error("Datos de asientos no válidos:", response.data);
        }
      } catch (error) {
        console.error("Error al obtener grupos de asientos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSeatGroups();
  }, [Id, backendUrl]);

  const toggleSeatSelection = (selectedSeat) => {
    const newSelectedSeats = new Set(selectedSeats);
    if (newSelectedSeats.has(selectedSeat)) {
      newSelectedSeats.delete(selectedSeat);
    } else {
      newSelectedSeats.add(selectedSeat);
    }
    setSelectedSeats(newSelectedSeats);
  };

  const handleFinishConfiguration = async () => {
    console.log("Array seleccionado:", Array.from(selectedSeats));
    try {
      const selectedSeatIds = Array.from(selectedSeats).map((seat) => seat.id);
      const response = await axios.post(
        `${backendUrl}/actualizarasientos.php`,
        {
          seatIds: selectedSeatIds,
          eventId: Id, // Agregar la ID del evento al cuerpo de la solicitud
        }
      );
      setServerMessage(response.data);
    } catch (error) {
      console.error("Error al actualizar los asientos:", error);
    }
  };

  const handleScroll = (e) => {
    if (e.deltaY > 0) {
      setCurrentGroupIndex((prevIndex) =>
        prevIndex < Object.keys(seatGroups).length - 1
          ? prevIndex + 1
          : prevIndex
      );
    } else {
      setCurrentGroupIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  const selectAllSeatsInGroup = (groupNumber) => {
    const newSelectedSeats = new Set(selectedSeats);
    seatGroups[groupNumber].forEach((seat) => {
      newSelectedSeats.add(seat);
    });
    setSelectedSeats(newSelectedSeats);
  };

  const renderSeatGroups = () => {
    if (!seatGroups || Object.keys(seatGroups).length === 0) {
      return <p>No hay datos de asientos disponibles.</p>;
    }

    const sortedGroupNumbers = Object.keys(seatGroups).sort((a, b) => {
      // Convertir los números de grupo a enteros para una comparación correcta
      return a.localeCompare(b);
    });

    return sortedGroupNumbers.map((groupNumber, index) => (
      <div
        key={index}
        className={`border border-gray-300 rounded-lg p-4 mb-4 ${
          index === currentGroupIndex ? "" : "hidden"
        }`}
        style={{ width: "500px", height: "700px" }}
      >
        <h3 className="text-lg font-semibold mb-2">Grupo {groupNumber}</h3>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4"
          onClick={() => selectAllSeatsInGroup(groupNumber)}
        >
          Seleccionar Todo como VIP
        </button>
        <div className="flex flex-wrap justify-center">
          {seatGroups[groupNumber].map((seat) => (
            <button
              key={seat.id}
              className={`bg-gray-300 text-gray-700 px-4 py-2 rounded-md m-1 ${
                selectedSeats.has(seat)
                  ? "bg-yellow-300 text-yellow-800 shadow-lg"
                  : ""
              }`}
              onClick={() => toggleSeatSelection(seat)}
            >
              {seat.seat_number}
            </button>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <div className="flex flex-col items-center h-screen" onWheel={handleScroll}>
      <h2 className="text-2xl mb-4">
        Configuración de Asientos VIP para evento {Id || "vacio"}
      </h2>
      {loading ? <p>Cargando...</p> : renderSeatGroups()}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        onClick={handleFinishConfiguration}
      >
        Finalizar Configuración
      </button>
      {serverMessage && (
        <div>
          <p>{serverMessage.message}</p>
        </div>
      )}
    </div>
  );
};

export default VipSeatConfiguration;

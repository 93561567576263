import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AdminFormulario from "./Adminformulario";
import { EnvironmentContext } from "../../EnvironmentVariables";

const Administrar = ({ eventoId, onEventoActualizado, eventoSeleccionado }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    time: "",
    biografia: "", // Nuevo campo
    fecha_final: "", // Nuevo campo
    facebook: "", // Nuevo campo
    instagram: "", // Nuevo campo
    image_url: "",
    image_url_2: "", // Nuevo campo
    image_url_3: "", // Nuevo campo
    lugar: "", // Nuevo campo
    participants: "", // Nuevo campo
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    if (eventoSeleccionado) {
      setFormData({
        title: eventoSeleccionado.title,
        description: eventoSeleccionado.description,
        date: eventoSeleccionado.date,
        time: eventoSeleccionado.time,
        biografia: eventoSeleccionado.biografia || "", // Nuevo campo
        fecha_final: eventoSeleccionado.fecha_final || "", // Nuevo campo
        facebook: eventoSeleccionado.facebook || "", // Nuevo campo
        instagram: eventoSeleccionado.instagram || "", // Nuevo campo
        lugar: eventoSeleccionado.lugar || "",
        participants: eventoSeleccionado.participants || "",
        image_url: eventoSeleccionado.image_url,
        image_url_2: eventoSeleccionado.image_url_2,
        image_url_3: eventoSeleccionado.image_url_3,
      });
    }
  }, [eventoSeleccionado]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Verificar si todos los campos obligatorios están presentes
      const requiredFields = ["title", "description", "date", "time"];
      if (requiredFields.some((field) => !formData[field])) {
        throw new Error("Error: Faltan datos obligatorios.");
      }

      console.log("Datos enviados al backend:", formData); // Imprimir los datos antes de enviar la solicitud

      const response = await axios.post(`${backendUrl}/actualizaradmin.php`, {
        id: eventoId,
        ...formData,
      });
      console.log(response.data);
      onEventoActualizado();
      setLoading(false);
      await mostrarAlerta();
      // Recargar la página
      window.location.reload(); // Otra opción: navigate(0);
    } catch (error) {
      console.error("Error al enviar el formulario:", error.message);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const mostrarAlerta = () => {
    return Swal.fire({
      icon: "success",
      title: "Datos configurados correctamente",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div className="w-full mx-auto">
      {loading && <div className="text-center">Cargando...</div>}
      <AdminFormulario
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Administrar;

import React, { useState, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { EnvironmentContext } from "../../EnvironmentVariables";

const SeatSelection = ({ eventId, onInsert }) => {
  const [loading, setLoading] = useState(false);
  const [showVipConfiguration, setShowVipConfiguration] = useState(false);
  const navigate = useNavigate();

  const { backendUrl } = useContext(EnvironmentContext);

  const cloneSeats = async () => {
    setLoading(true);
    try {
      // Clonar asientos
      const response = await axios.get(
        `${backendUrl}/insert_seats.php?event_id=${eventId}`
      );
      // Manejar la respuesta del servidor
      console.log(response.data);
      // Mostrar SweetAlert para confirmar configuración VIP
      await Swal.fire({
        icon: "success",
        title: "Completado",
        text: response.data.message,
        showCancelButton: true,
        confirmButtonText: "Configurar asientos VIP",
        cancelButtonText: "Omitir",
      }).then((result) => {
        if (result.isConfirmed) {
          setShowVipConfiguration(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate("/"); // Redirigir al usuario a / si cancela
        }
      });
    } catch (error) {
      console.error("Error clonando asientos:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al clonar los asientos.",
      });
    } finally {
      setLoading(false);
    }
  };

  if (showVipConfiguration) {
    navigate(`/dashboard/vip-seat-configuration/${eventId}`); // Navegar a la nueva ruta con la ID del evento incluida en la URL
    return null; // No renderizar nada aquí, ya que estamos redirigiendo a otra página
  }

  return (
    <div className="text-center">
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
        onClick={cloneSeats}
        disabled={loading}
      >
        {loading ? "Clonando Asientos..." : "Clonar Asientos"}
      </button>
    </div>
  );
};

export default SeatSelection;

import React, { useState, useEffect, useContext } from "react";
import { EnvironmentContext } from "../EnvironmentVariables";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Acceso = () => {
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const verificarAdmin = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        const userData = JSON.parse(userDataString);
        const email = userData?.email;

        console.log("Correo electrónico enviado al servidor:", email);

        if (!email) {
          throw new Error("No se proporcionó un correo electrónico");
        }

        const response = await fetch(
          `${backendUrl}/admins.php?email=${encodeURIComponent(email)}`
        );
        if (!response.ok) {
          throw new Error("Error al verificar el acceso");
        }
        const data = await response.json();
        setIsAdmin(data.isAdmin);

        // Mostrar mensaje de bienvenida con SweetAlert
      } catch (error) {
        console.error("Error:", error);
        setIsAdmin(false);
        // Mostrar mensaje de error con SweetAlert
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      }
    };

    verificarAdmin();
  }, [backendUrl]);

  if (isAdmin === null) {
  }

  if (!isAdmin) {
    navigate("/error");
    return null;
  }
};

export default Acceso;

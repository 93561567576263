import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { BiWallet } from "react-icons/bi";
import SpinnerModal from "./SpinnerModal";

const PayButton = ({ total, items, eventId, onSuccess, onError }) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("userData");
    if (userDataFromStorage) {
      const userData = JSON.parse(userDataFromStorage);
      setEmail(userData.email);
      setUsername(userData.name);
    }
  }, []);

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);

    const timeoutId = setTimeout(() => {
      setLoading(false);
      setError(
        "El proceso está tardando demasiado. Por favor, intenta de nuevo."
      );
    }, 15000); // 15 segundos

    try {
      items.forEach((item) => {
        console.log("Item ID:", item.id);
        console.log("Seat Number:", item.seat_number);
        console.log("Price:", item.price);
        console.log("Event_id:", item.event_id);
        console.log("Type:", item.type);
      });

      const response = await axios.post(
        "https://payapi-8kog.onrender.com/create-order",
        {
          email: email,
          username: username,
          id_event: eventId,
          items: items,
        }
      );

      clearTimeout(timeoutId);
      setLoading(false);

      const data = response.data;
      const paypalUrl = data.links[1].href;

      // Redirigir al usuario a PayPal en la misma ventana
      window.location.href = paypalUrl;

      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (error) {
      clearTimeout(timeoutId);
      setLoading(false);
      console.error("Error en el pago:", error);
      setError("Error en el pago. Por favor, intenta de nuevo.");

      if (typeof onError === "function") {
        onError(error);
      }
    }
  };

  return (
    <>
      <motion.button
        onClick={handleCheckout}
        className="relative bg-gray-800 text-white font-bold py-2 px-4 rounded mt-4 w-full overflow-hidden flex items-center justify-center"
        whileHover={{
          background: "linear-gradient(to right, #fddb92, #fddb92)",
          color: "#111111",
        }}
        disabled={loading}
      >
        <BiWallet className="mr-2" />
        <span>Pagar ahora</span>
        <div className="absolute top-0 left-0 w-full h-full bg-blue-900 mix-blend-difference transition-all duration-300"></div>
        <svg
          className="absolute top-0 left-0 w-full h-full text-yellow-400"
          fill="none"
          viewBox="0 0 576 512"
        >
          <motion.path
            d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={{ duration: 0.5 }}
          />
        </svg>
      </motion.button>
      {loading && (
        <SpinnerModal
          isOpen={loading}
          onClose={() => setLoading(false)}
          message="Procesando pago..."
        />
      )}
      {error && <div className="mt-4 text-red-500 text-center">{error}</div>}
    </>
  );
};

export default PayButton;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { HiOutlineArrowNarrowUp, HiArrowLeft } from "react-icons/hi";
import Entradas from "./entradas";
import { EnvironmentContext } from "../../EnvironmentVariables"; // Importa el contexto de las variables de entorno

const Formulario = ({ eventoId, tituloEvento }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [totalTransacciones, setTotalTransacciones] = useState(0);
  const [transaccionesCanjeadas, setTransaccionesCanjeadas] = useState(0);

  // Obtenemos el valor de backendUrl del contexto de variables de entorno
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const obtenerInformacionTransacciones = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/asistenciaadmin.php?eventoId=${eventoId}`
        );

        // Obtener el total de transacciones
        const total = response.data.length;

        // Contar cuántas transacciones tienen el estado "canjeado"
        const canjeadas = response.data.reduce(
          (count, transaccion) =>
            count + (transaccion.state === "canjeado" ? 1 : 0),
          0
        );

        setTotalTransacciones(total);
        setTransaccionesCanjeadas(canjeadas);
      } catch (error) {
        console.error(
          "Error al obtener la información de las transacciones:",
          error
        );
      }
    };

    obtenerInformacionTransacciones();
  }, [eventoId, backendUrl]); // Agregamos backendUrl y eventoId como dependencias

  // Función para volver atrás o recargar la página
  const handleBackButtonClick = () => {
    if (eventoId) {
      // Si hay un evento seleccionado, recargar la página
      window.location.reload();
    } else {
      // Si no hay evento seleccionado, ir atrás en el historial
      window.history.back();
    }
  };

  // Función para regresar arriba
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex">
      {/* Botón para volver atrás */}
      <button
        className="flex items-center justify-center border border-gray-300 rounded w-10 h-10 ml-4 mt-4 focus:outline-none"
        onClick={handleBackButtonClick}
      >
        <HiArrowLeft className="text-gray-500" />
      </button>

      <div className="p-4">
        <h2 className="text-lg font-semibold mb-4">{tituloEvento}</h2>
        <p>ID del evento: {eventoId}</p>
        <p>Total: {totalTransacciones}</p>
        <p>Canjeadas: {transaccionesCanjeadas}</p>
      </div>

      <Entradas eventoId={eventoId} searchTerm={searchTerm} />

      {/* Icono para regresar arriba */}
      <button
        className="flex items-center justify-center border border-gray-300 rounded-full w-10 h-10 fixed bottom-4 right-4 z-10 focus:outline-none bg-white"
        onClick={scrollToTop}
      >
        <HiOutlineArrowNarrowUp className="text-gray-500" />
      </button>
    </div>
  );
};

export default Formulario;

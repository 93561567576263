// Router.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import Compra from "./components/compra.js";
import Home from "./components/home";
import Dashboard from "./components/dashboard.js";
import Transacciones from "./components/transacciones.js";
import ErrorPage from "./log/ErrorPage.js";
import Entradas from "./components/formadmin/Entradas.js";
import Ticket from "./components/ticket.js";
import Detalles from "./components/detalles.js";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/compra/:id" element={<Compra />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/transacciones/*" element={<Transacciones />} />
      <Route path="/error/*" element={<ErrorPage />} />
      <Route path="/detalles/:id" element={<Detalles />} />
      <Route
        path="/ticket/:id/:username/:email/:timestamp/:amount/:token/:seat_id/:event_id"
        element={<Ticket />}
      />
      <Route path="/entradas/" element={<Entradas />} />
    </Routes>
  );
};

export default AppRoutes;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2"; // Importar SweetAlert2
import { EnvironmentContext } from "../EnvironmentVariables";

const Desactivar = () => {
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(false); // Definir loading con useState

  useEffect(() => {
    // Obtener eventos con estado "activo" desde el servidor
    axios
      .get("https://backend.ccibarcelona.org/infoeventos.php")
      .then((response) => {
        // Filtrar eventos con estado "activo"
        const eventosActivos = response.data.filter(
          (evento) => evento.state === "activo"
        );
        setEventos(eventosActivos);
      })
      .catch((error) => {
        console.error("Error al obtener eventos:", error);
      });
  }, []);

  // Función para mostrar la alerta
  const mostrarAlerta = (mensaje, tipo) => {
    return Swal.fire({
      icon: tipo,
      title: mensaje,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const { backendUrl } = useContext(EnvironmentContext);

  const handleFinalizarEvento = async (id) => {
    if (!id) {
      console.error("No se proporcionó la ID del evento.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/desactivar.php?id=${id}`);
      console.log(response.data);
      setLoading(false);
      mostrarAlerta("Evento finalizado correctamente", "success").then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Error al finalizar evento:", error);
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-semibold mb-4">Eventos Activos </h1>
      {eventos.length === 0 ? (
        <div className="justify-center items-center h-screen text-gray-500">
          No hay eventos activos
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {eventos.map((evento) => (
            <div key={evento.id} className="bg-white rounded shadow-md p-4">
              <h2 className="text-xl font-semibold mb-2">{evento.title}</h2>
              <p className="text-gray-600 mb-4">{evento.description}</p>
              <p className="text-gray-500 mb-2">Fecha: {evento.date}</p>
              <p className="text-gray-500 mb-4">Hora: {evento.time}</p>
              <button
                onClick={() => handleFinalizarEvento(evento.id)}
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-red-300"
              >
                Finalizar
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Desactivar;

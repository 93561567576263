import React, { useState } from "react";
import EventSelection from "./EventSelection";
import SeatSelection from "./SeatSelection";

const EventConfigurationWizard = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedEventId, setSelectedEventId] = useState(null); // Estado para almacenar la ID del evento seleccionado
  const handleNextStep = (eventId) => {
    setSelectedEventId(eventId); // Establece la ID del evento seleccionado
    setCurrentStep(currentStep + 1);
  };

  const handleFinishStep = () => {
    setSelectedEventId(null); // Elimina la ID del evento una vez que se completa la configuración
  };

  return (
    <div className="transition-opacity duration-500">
      {currentStep === 1 && <EventSelection onNextStep={handleNextStep} />}
      {currentStep === 2 && (
        <SeatSelection eventId={selectedEventId} onInsert={handleNextStep} />
      )}
    </div>
  );
};

export default EventConfigurationWizard;

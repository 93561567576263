import React, { useState, useEffect, useContext } from "react";
import { EnvironmentContext } from "../EnvironmentVariables";
import { HiOutlineTicket } from "react-icons/hi2";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Transacciones = () => {
  const [transacciones, setTransacciones] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const { backendUrl } = useContext(EnvironmentContext);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();

  const userDataFromStorage = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userDataFromStorage ? userDataFromStorage.email : null;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!userEmail || loaded) return;

    const obtenerTransacciones = async () => {
      try {
        const respuesta = await fetch(`${backendUrl}/transacciones.php`);
        if (!respuesta.ok) {
          throw new Error("Error al obtener los datos del servidor");
        }
        const datos = await respuesta.json();
        const transaccionesFiltradas = datos.filter(
          (transaccion) => transaccion.email === userEmail
        );
        if (transaccionesFiltradas.length === 0) {
          console.log("No se encontraron transacciones para el usuario.");
          return;
        }
        transaccionesFiltradas.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setTransacciones(transaccionesFiltradas);
        setLoaded(true);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    obtenerTransacciones();
  }, [backendUrl, userEmail, loaded]);

  const handleTransactionClick = (id) => {
    console.log("ID de transacción seleccionada:", id);
    navigate(
      `/ticket/${id}/${transacciones.find((t) => t.id === id).username}/${transacciones.find((t) => t.id === id).email}/${transacciones.find((t) => t.id === id).timestamp}/${transacciones.find((t) => t.id === id).amount}/${transacciones.find((t) => t.id === id).token}/${transacciones.find((t) => t.id === id).seat_id}/${transacciones.find((t) => t.id === id).event_id}`
    );
  };

  return (
    <div className="text-gray-900 min-h-screen bg-white">
      <Navbar />
      <div className="container mx-auto px-2 lg:px-4 flex flex-col lg:flex-row">
        <div className="w-full lg:w-full mt-5 overflow-x-auto overflow-y-auto">
          {transacciones.map((transaccion, index) => (
            <div key={index}>
              <div className="text-lg font-medium text-gray-700 mt-4">
                {new Date(transaccion.timestamp).toLocaleDateString("es-ES", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
              <div>
                {transacciones
                  .filter(
                    (t) =>
                      new Date(t.timestamp).toLocaleDateString() ===
                      new Date(transaccion.timestamp).toLocaleDateString()
                  )
                  .map((t) => (
                    <div
                      key={t.id}
                      className="border border-gray-300 rounded-md cursor-pointer p-4 mb-4 lg:mb-2 lg:mr-2 bg-gray-100"
                      onClick={() => handleTransactionClick(t.id)}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <HiOutlineTicket className="h-6 w-6 mr-2 text-gray-700" />
                          <div className="text-sm font-semibold text-gray-800">
                            {t.username}
                          </div>
                        </div>
                        <div className="text-sm text-gray-800">${t.amount}</div>
                      </div>
                      <div className="text-sm mt-1 text-gray-700">
                        {t.email}
                      </div>
                      {isMobile && (
                        <div className="text-xs mt-1 text-gray-600">
                          {new Date(t.timestamp).toLocaleDateString("es-ES", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Transacciones;

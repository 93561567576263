import React from "react";
import { Ganancias } from "./stats/ganancias";

const Data = () => {
  return (
    <div>
      <Ganancias />
    </div>
  );
};

export default Data;

import React, { useState, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import keys from "./keys";

const GoogleLoginButton = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDataStored, setUserDataStored] = useState(false);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("userData");
    if (userDataFromStorage) {
      setUserDataStored(true);
    }
  }, []);

  useEffect(() => {
    const initializeAuth2 = async () => {
      await new Promise((resolve) => {
        gapi.load("auth2", resolve);
      });
      const auth2 = gapi.auth2.init({
        client_id: keys.google.clientID,
        cookie_policy: "single_host_origin",
      });
      auth2.then(() => console.log("Google Auth2 initialized successfully"));
    };
    initializeAuth2();
  }, []);

  const responseGoogleSuccess = (response) => {
    console.log("Inicio de sesión exitoso:", response);
    setIsLoggedIn(true);

    // Extraer los datos del usuario de la respuesta
    const {
      profileObj: { name, email, imageUrl },
    } = response;

    // Guardar los datos del usuario en el almacenamiento local
    const userData = {
      name: name,
      email: email,
      imageUrl: imageUrl,
    };
    localStorage.setItem("userData", JSON.stringify(userData));
    setUserDataStored(true);
  };

  const responseGoogleFailure = (error) => {
    console.error("Error en el inicio de sesión con Google:", error);
  };

  useEffect(() => {
    if (isLoggedIn) {
      // Recargar la página después de iniciar sesión exitosamente
      window.location.reload();
    }
  }, [isLoggedIn]);

  return (
    <div>
      {isLoggedIn ? null : (
        <>
          {userDataStored ? (
            <p>
              Los datos del usuario están guardados en el almacenamiento local.
            </p>
          ) : (
            <GoogleLogin
              clientId={keys.google.clientID}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="flex flex-wrap justify-center w-full border border-gray-300 hover:border-gray-500 px-2 py-1.5 rounded-md"
                >
                  <img
                    className="w-5 mr-2"
                    src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                    alt="Logo de Google"
                  />
                  Iniciar sesión con Google
                </button>
              )}
              onSuccess={responseGoogleSuccess}
              onFailure={responseGoogleFailure}
              cookiePolicy={"single_host_origin"}
            />
          )}
        </>
      )}
    </div>
  );
};

export default GoogleLoginButton;

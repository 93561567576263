import React, { useState, useEffect } from "react";
import LoginButton from "./LoginButton";
import DropdownMenu from "./menu";

const Init = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [userDataExists, setUserDataExists] = useState(false);
  const [showProfileCard, setShowProfileCard] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false); // Nuevo estado

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("userData");
    if (userDataFromStorage) {
      setUserDataExists(true);
      setUserData(JSON.parse(userDataFromStorage));
    }

    const isMobileDevice = window.matchMedia("(max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
  }, []);

  const handleToggleLogin = () => {
    setShowLogin(!showLogin);
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    setUserDataExists(false);
    setUserData(null);
  };

  const handleProfileClick = () => {
    setShowProfileCard(!showProfileCard);
    setIsProfileMenuOpen(!isProfileMenuOpen); // Cambia el estado del menú desplegable del perfil
  };

  return (
    <div className="flex justify-center  text-gray-800">
      {!userDataExists ? (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
          onClick={handleToggleLogin}
        >
          Iniciar sesión
        </button>
      ) : (
        <div className="relative">
          <div className="flex items-center">
            {!isMobile && (
              <div
                className="flex items-center cursor-pointer"
                onClick={handleProfileClick}
              >
                <img
                  src={userData.imageUrl}
                  alt="Imagen de perfil"
                  className="w-8 h-8 rounded-full mr-2 cursor-pointer"
                />
                <p className="cursor-pointer">{userData.name}</p>
              </div>
            )}
            {isMobile && (
              <div
                className="flex items-center cursor-pointer"
                onClick={handleProfileClick}
              >
                <img
                  src={userData.imageUrl}
                  alt="Imagen de perfil"
                  className="w-8 h-8 rounded-full mr-2 cursor-pointer"
                />
              </div>
            )}
          </div>
          {showProfileCard && (
            <DropdownMenu
              handleLogout={handleLogout}
              handleDropdownClick={handleProfileClick}
              userData={userData}
              show={showProfileCard}
              isProfileMenuOpen={isProfileMenuOpen} // Pasa el estado del menú desplegable del perfil
              setIsProfileMenuOpen={setIsProfileMenuOpen} // Pasa la función para cambiar el estado del menú desplegable del perfil
            />
          )}
        </div>
      )}
      {showLogin && <LoginButton onClose={handleToggleLogin} />}
    </div>
  );
};

export default Init;

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import EnvironmentVariables from "./EnvironmentVariables";

const root = createRoot(document.getElementById("root"));

root.render(
  <div className="relative">
    <div className="scrollbar-corner-neutral-900 overflow-y-auto h-screen">
      <EnvironmentVariables>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </EnvironmentVariables>
    </div>
  </div>
);

reportWebVitals();

import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import moment from "moment-timezone";
import "moment/locale/es"; // Importa el idioma español para moment.js
import { EnvironmentContext } from "../EnvironmentVariables";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Tarjetas = () => {
  const [eventos, setEventos] = useState([]);
  const [filtro, setFiltro] = useState("");
  const navigate = useNavigate();
  const carruselRef = useRef(null);

  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const obtenerEventos = async () => {
      try {
        const respuesta = await fetch(`${backendUrl}/infoeventos.php`);
        if (!respuesta.ok) {
          throw new Error("Error al obtener los datos del servidor");
        }
        const datos = await respuesta.json();
        const eventosActivos = datos.filter(
          (evento) => evento.state === "activo"
        );
        setEventos(eventosActivos);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    obtenerEventos();
  }, [backendUrl]);

  const comprarEntrada = (eventoId) => {
    navigate(`/detalles/${eventoId}`);
  };

  const eventosFiltrados = eventos.filter((evento) =>
    evento.title.toLowerCase().includes(filtro.toLowerCase())
  );

  const tarjetasVisibles = [...eventosFiltrados];
  while (tarjetasVisibles.length < 4) {
    tarjetasVisibles.push({ id: `placeholder-${tarjetasVisibles.length}` });
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.25, // Muestra 1.25 tarjetas para que se vea una tarjeta y un cuarto de otra
          slidesToScroll: 1,
          centerMode: true, // Activa el modo centro para móviles
        },
      },
    ],
  };

  const handleCarruselScroll = (event) => {
    event.preventDefault();
    if (event.deltaY > 0) {
      carruselRef.current.slickNext();
    } else {
      carruselRef.current.slickPrev();
    }
  };

  return (
    <div
      className="container mx-auto relative overflow-hidden"
      onWheel={handleCarruselScroll}
    >
      <div className="flex justify-center">
        <div className="container mx-auto relative overflow-hidden ml-4 md:ml-0 lg:max-w-6xl lg:mx-auto ">
          {/* Centrado en pantallas grandes */}
          <p className="text-xs text-left ml-2 mt-2">Eventos Actuales</p>
          <Slider ref={carruselRef} {...settings}>
            {tarjetasVisibles.map((evento) =>
              evento.id.startsWith("placeholder-") ? (
                <div key={evento.id} className="p-2">
                  <div className="group block rounded-xl overflow-hidden bg-transparent w-full aspect-square "></div>
                </div>
              ) : (
                <div
                  key={evento.id}
                  className="cursor-pointer p-2"
                  onClick={() => comprarEntrada(evento.id)}
                >
                  <div className="group block rounded-xl overflow-hidden bg-gray-200  relative w-full aspect-square p-2 space-y-2">
                    <ImageRotator
                      urls={[
                        evento.image_url,
                        evento.image_url_2,
                        evento.image_url_3,
                      ]}
                    />
                    <div className="text-center">
                      <h3 className="text-xs font-medium text-black">
                        {evento.title}
                      </h3>
                      <p className="text-xs text-gray-600 dark:text-neutral-400">
                        {evento.participants}
                      </p>
                    </div>
                    <div className="absolute top-0 right-1 bg-[#6028FF] text-purple-200 text-sm font-semibold px-2 py-1 rounded flex flex-col items-center w-12 h-12 justify-center z-10">
                      <div className="text-lg font-bold">
                        {moment(evento.date).tz("Europe/Madrid").format("DD")}
                      </div>
                      <div className="text-xs uppercase">
                        {moment(evento.date).tz("Europe/Madrid").format("MMM")}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

const ImageRotator = ({ urls }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const validUrls = urls.filter(Boolean);
    if (validUrls.length > 1) {
      const interval = setInterval(() => {
        setFade(true);
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % validUrls.length);
          setFade(false);
        }, 400); // Adjust this duration to match your fade-out duration
      }, 3000); // Cambia cada 3 segundos
      return () => clearInterval(interval);
    }
  }, [urls]);

  const validUrls = urls.filter(Boolean);
  const currentUrl = validUrls[currentIndex];

  return (
    <div className="relative w-full h-32 sm:h-52">
      {validUrls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt=""
          className={`absolute inset-0 w-full h-full object-cover rounded-xl transition-opacity duration-1500 ease-in-out [box-shadow:rgb(0,0,0)_6px_6px] ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          } ${fade && index === currentIndex ? "animate-squeeze" : ""}`}
        />
      ))}
    </div>
  );
};

export default Tarjetas;

import React, { Fragment, useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, TrashIcon, StarIcon } from "@heroicons/react/24/outline";
import PayButton from "./PayButton";
import Gratis from "./gratis";
import { EnvironmentContext } from "../../EnvironmentVariables";

const Carrito = ({ selectedSeats, onClose }) => {
  const [event, setEvent] = useState(null);
  const [seatDetails, setSeatDetails] = useState([]);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const { backendUrl } = useContext(EnvironmentContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventResponse, seatResponse] = await Promise.all([
          axios.get(`${backendUrl}/infoeventos.php?id=${id}`),
          axios.get(`${backendUrl}/asientos.php?eventId=${id}`),
        ]);

        const eventData = eventResponse.data.find((item) => item.id === id);
        setEvent(eventData);

        const seatData = seatResponse.data.filter((seat) =>
          selectedSeats.includes(seat.id)
        );
        setSeatDetails(seatData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(
          "Error al obtener datos. Por favor, inténtalo de nuevo más tarde."
        );
      }
    };

    fetchData();

    const userDataFromStorage = localStorage.getItem("userData");
    if (userDataFromStorage) {
      const userData = JSON.parse(userDataFromStorage);
      setUsername(userData.name);
      setEmail(userData.email);
    }
  }, [id, selectedSeats, backendUrl]);

  const calculateTotalPrice = () => {
    if (!event || !seatDetails.length) return 0;

    const total = seatDetails.reduce((acc, seat) => {
      const price =
        seat.vip === "si" ? parseFloat(event.costvip) : parseFloat(event.cost);
      return acc + price;
    }, 0);

    return total;
  };

  const removeSeat = (seatId) => {
    const updatedSeatDetails = seatDetails.filter((seat) => seat.id !== seatId);
    setSeatDetails(updatedSeatDetails);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50 flex items-center justify-end"
        onClose={() => {
          onClose();
          setOpen(false);
        }}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed right-0 h-full w-96 bg-gray-800 text-white shadow-lg rounded-lg overflow-y-auto">
            <div className="px-6 py-4 border-b border-gray-600 flex justify-between items-center">
              <h1 className="text-lg font-semibold">Carrito</h1>
              <button
                type="button"
                onClick={() => {
                  onClose();
                  setOpen(false);
                }}
                className="text-gray-300 hover:text-white"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="px-6 py-4">
              {error && <p className="text-red-500">{error}</p>}
              {seatDetails.length === 0 ? (
                <p className="text-gray-400">No hay elementos en el carrito.</p>
              ) : (
                <ul className="divide-y divide-gray-600">
                  {seatDetails.map((seat) => (
                    <li
                      key={seat.id}
                      className="py-4 flex items-center justify-between"
                    >
                      <div>
                        {seat.vip === "si" ? (
                          <p className="text-gray-400 flex items-center">
                            Asiento VIP: {seat.seat_number}
                            <StarIcon className="h-5 w-5 text-yellow-500 ml-2" />
                          </p>
                        ) : (
                          <p className="text-gray-400">Entrada Normal</p>
                        )}
                      </div>
                      <div className="text-right">
                        <p className="text-gray-400">
                          Precio:{" "}
                          <span className="font-semibold">
                            {event &&
                              (seat.vip === "si"
                                ? parseFloat(event.costvip)
                                : parseFloat(event.cost))}{" "}
                            euros
                          </span>
                        </p>
                      </div>
                      <button
                        onClick={() => removeSeat(seat.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              {calculateTotalPrice() === 0
                ? seatDetails.length > 0 && (
                    <Gratis
                      username={username}
                      email={email}
                      seatDetails={seatDetails}
                      eventId={id}
                      onSuccess={() => console.log("Asiento obtenido")}
                      onError={(error) =>
                        console.error("Error al obtener asiento:", error)
                      }
                    />
                  )
                : seatDetails.length > 0 && (
                    <PayButton
                      total={calculateTotalPrice()}
                      items={seatDetails.map((seat) => ({
                        id: seat.id,
                        seat_number: seat.seat_number,
                        price:
                          seat.vip === "si"
                            ? parseFloat(event.costvip)
                            : parseFloat(event.cost),
                        event_id: id,
                        type: seat.vip === "si" ? "VIP" : "Normal", // Añadimos el tipo de asiento
                      }))}
                      eventId={id}
                      onSuccess={() => console.log("Pago exitoso")}
                      onError={(error) => {
                        console.error("Error en el pago:", error);
                        setError(
                          "Error al procesar el pago. Por favor, inténtalo de nuevo más tarde."
                        );
                      }}
                    />
                  )}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default Carrito;

import React from "react";
import Banner from "../../img/banner.png"; // Asegúrate de que la ruta sea correcta

const OficialCard = () => {
  return (
    <div>
      <header className="text-white">
        {/* Container */}
        <div className="px-5 py-14 max-[479px]:py-8">
          {/* Enlace envolvente */}
          <a
            href="https://ccibarcelona.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* Component */}
            <div
              className="mx-auto max-w-6xl rounded-[8px] bg-cover bg-center bg-no-repeat py-20 text-center [box-shadow:rgb(175,165,255)_9px_9px] max-[479px]:py-8 max-[479px]:h-24 flex items-center justify-center"
              style={{ backgroundImage: `url(${Banner})` }}
            >
              {/* Heading Text */}
              <div className="mx-auto max-w-[720px]">
                <h2 className="mb-4 text-3xl font-semibold md:text-5xl max-[479px]:text-lg">
                  PÁGINA PRINCIPAL EN CONSTRUCIÓN
                </h2>
              </div>
            </div>
          </a>
        </div>
      </header>
    </div>
  );
};

export default OficialCard;

import React, { useState } from "react";
import { Tab, TabGroup, TabList } from "@tremor/react";

const AdminFormulario = ({ formData, handleChange, handleSubmit }) => {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  return (
    <form onSubmit={handleSubmit} className="rounded-lg p-4 w-full h-full">
      {/* Título del evento */}
      <div className="mb-4">
        <label htmlFor="title" className="block text-gray-700 font-bold mb-2">
          Título:
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={formData.title}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Título"
        />
      </div>

      {/* Descripción del evento */}
      <div className="mb-4">
        <label
          htmlFor="description"
          className="block text-gray-700 font-bold mb-2"
        >
          Descripción:
        </label>
        <textarea
          name="description"
          id="description"
          value={formData.description}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Descripción"
        />
      </div>

      {/* Fechas de inicio y final */}
      <div className="flex justify-between mb-4">
        <div className="w-1/2 pr-2">
          <label htmlFor="date" className="block text-gray-700 font-bold mb-2">
            Fecha de inicio (YYYY-MM-DD):
          </label>
          <input
            type="text"
            name="date"
            id="date"
            value={formData.date}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Fecha de inicio"
          />
        </div>
        <div className="w-1/2 pl-2">
          <label
            htmlFor="fecha_final"
            className="block text-gray-700 font-bold mb-2"
          >
            Fecha final (YYYY-MM-DD):
          </label>
          <input
            type="text"
            name="fecha_final"
            id="fecha_final"
            value={formData.fecha_final}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Fecha final"
          />
        </div>
      </div>

      {/* Hora del evento */}
      <div className="mb-4">
        <label htmlFor="time" className="block text-gray-700 font-bold mb-2">
          Hora:
        </label>
        <input
          type="text"
          name="time"
          id="time"
          value={formData.time}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Hora"
        />
      </div>

      {/* Biografía */}
      <div className="mb-4">
        <label
          htmlFor="biografia"
          className="block text-gray-700 font-bold mb-2"
        >
          Biografía:
        </label>
        <textarea
          name="biografia"
          id="biografia"
          value={formData.biografia}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Biografía"
        />
      </div>

      {/* Facebook e Instagram */}
      <div className="flex justify-between mb-4">
        <div className="w-1/2 pr-2">
          <label
            htmlFor="facebook"
            className="block text-gray-700 font-bold mb-2"
          >
            Facebook:
          </label>
          <input
            type="text"
            name="facebook"
            id="facebook"
            value={formData.facebook}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Facebook"
          />
        </div>
        <div className="w-1/2 pl-2">
          <label
            htmlFor="instagram"
            className="block text-gray-700 font-bold mb-2"
          >
            Instagram:
          </label>
          <input
            type="text"
            name="instagram"
            id="instagram"
            value={formData.instagram}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Instagram"
          />
        </div>
      </div>

      {/* Ubicación */}
      <div className="mb-4">
        <label htmlFor="lugar" className="block text-gray-700 font-bold mb-2">
          Ubicación:
        </label>
        <input
          type="text"
          name="lugar"
          id="lugar"
          value={formData.lugar}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Ubicación"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="participants"
          className="block text-gray-700 font-bold mb-2"
        >
          Organizadores:
        </label>
        <input
          type="text"
          name="participants"
          id="participants"
          value={formData.participants}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Organizadores"
        />
      </div>

      {/* Tabs for image URLs */}
      <TabGroup className="p-2">
        <TabList variant="line" defaultValue="1">
          <Tab value="1" onClick={() => handleTabChange("1")}>
            Imagen 1
          </Tab>
          <Tab value="2" onClick={() => handleTabChange("2")}>
            Imagen 2
          </Tab>
          <Tab value="3" onClick={() => handleTabChange("3")}>
            Imagen 3
          </Tab>
        </TabList>
      </TabGroup>

      {/* URL de la imagen */}
      {activeTab === "1" && (
        <div className="mb-4">
          <input
            type="text"
            name="image_url"
            id="image_url"
            value={formData.image_url}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="URL de la Imagen 1"
          />
        </div>
      )}

      {activeTab === "2" && (
        <div className="mb-4">
          <input
            type="text"
            name="image_url_2"
            id="image_url_2"
            value={formData.image_url_2}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="URL de la Imagen 2"
          />
        </div>
      )}

      {activeTab === "3" && (
        <div className="mb-4">
          <input
            type="text"
            name="image_url_3"
            id="image_url_3"
            value={formData.image_url_3}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="URL de la Imagen 3"
          />
        </div>
      )}

      {/* Botón de envío */}
      <button
        type="submit"
        className="bg-blue-300 hover:bg-blue-500 text-blue-800 font-bold py-2 px-4 rounded mt-2"
      >
        Subir Cambios
      </button>
    </form>
  );
};

export default AdminFormulario;

import React, { useState, useEffect, useContext } from "react";
import {
  TextInput,
  Select,
  SelectItem,
  Button,
  NumberInput,
} from "@tremor/react";
import { Badge } from "@tremor/react";
import { RiRecordCircleFill } from "@remixicon/react";
import axios from "axios";
import { EnvironmentContext } from "../../EnvironmentVariables";

const Reservar = ({ eventId }) => {
  const { backendUrl } = useContext(EnvironmentContext);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [grupo, setGrupo] = useState("A");
  const [cantidad, setCantidad] = useState(1);
  const [asientosDisponibles, setAsientosDisponibles] = useState(0);
  const [mensaje, setMensaje] = useState("");
  const [asientosReservados, setAsientosReservados] = useState([]);
  const [precioGrupo, setPrecioGrupo] = useState(0);
  const [cost, setCost] = useState(0);
  const [costVip, setCostVip] = useState(0);
  const [esVip, setEsVip] = useState(false); // Nuevo estado para determinar si el grupo es VIP

  const obtenerAsientosDisponibles = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/asientos.php?eventId=${eventId}`
      );

      const asientosValidos = response.data.filter(
        (asiento) =>
          asiento.is_available === "si" && asiento.groupnumber === grupo
      );

      if (asientosValidos.length > 0) {
        const esVip = asientosValidos[0].vip === "si";
        setEsVip(esVip);
        setPrecioGrupo(esVip ? costVip : cost); // Establecer precio según si es VIP o no
      }

      setAsientosDisponibles(asientosValidos.length);
    } catch (error) {
      console.error("Error al obtener asientos disponibles:", error);
    }
  };

  const obtenerPrecios = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/infoeventos.php?eventId=${eventId}`
      );
      const evento = response.data;
      setCost(evento.cost);
      setCostVip(evento.costvip);
    } catch (error) {
      console.error("Error al obtener precios del evento:", error);
      setCost(0);
      setCostVip(0);
    }
  };

  useEffect(() => {
    obtenerPrecios();
  }, [eventId]);

  useEffect(() => {
    obtenerAsientosDisponibles();
  }, [backendUrl, eventId, grupo]);

  const handleReservar = async () => {
    if (!nombre || !correo || !grupo || cantidad <= 0) {
      setMensaje("Por favor, completa todos los campos correctamente.");
      return;
    }

    if (cantidad > asientosDisponibles) {
      setMensaje(
        `Solo hay ${asientosDisponibles} asientos disponibles en el grupo ${grupo}.`
      );
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/reservarGrupo.php`, {
        nombre,
        correo,
        grupo,
        cantidad,
        event_id: eventId,
        amount: precioGrupo, // Enviar el precio sin multiplicar
      });

      if (response.data.exito) {
        setMensaje("¡Reservación realizada exitosamente!");
        setAsientosReservados(response.data.asientosReservados);
        obtenerAsientosDisponibles(); // Recargar el contador de asientos disponibles
        setNombre("");
        setCorreo("");
        setCantidad(1);
      } else {
        setMensaje("Error al realizar la reservación.");
      }
    } catch (error) {
      console.error("Error al reservar asientos:", error);
      setMensaje("Error al comunicarse con el servidor.");
    }
  };

  return (
    <div className="flex flex-col items-center space-y-10 p-4 ml-40">
      <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8 w-full max-w-4xl">
        <div
          className="flex flex-col space-y-4 w-full max-w-md"
          style={{ marginLeft: "20px" }}
        >
          <h2 className="text-lg font-bold">Reservar Asientos por Grupo</h2>
          <TextInput
            placeholder="Nombre de la persona"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
          <TextInput
            placeholder="Correo Electrónico"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
          <Select
            value={grupo}
            onChange={(e) => setGrupo(e)}
            placeholder="Seleccionar Grupo"
          >
            {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].map((grupo) => (
              <SelectItem key={grupo} value={grupo} className="bg-gray-100">
                {grupo}
              </SelectItem>
            ))}
          </Select>
          {grupo && (
            <p className="mt-2">
              Asientos disponibles en el grupo {grupo}: {asientosDisponibles}
            </p>
          )}
          <NumberInput
            value={cantidad}
            onValueChange={(value) => setCantidad(value)}
            placeholder="Cantidad de asientos"
            min={1}
          />
          <Button onClick={handleReservar}>Reservar</Button>
          {mensaje && <p>{mensaje}</p>}

          {/* Mostrar los precios como badges */}
          <div className="flex space-x-4 mt-4">
            <Badge
              icon={RiRecordCircleFill}
              color={esVip ? "gray" : "blue"} // Iluminar azul si no es VIP
            >
              {cost}
            </Badge>
            <Badge
              icon={RiRecordCircleFill}
              color={esVip ? "green" : "gray"} // Iluminar verde si es VIP
            >
              {costVip}
            </Badge>
          </div>
        </div>

        <div
          className="flex flex-col w-80 overflow-y-auto bg-gray-100 p-4 rounded shadow"
          style={{ marginLeft: "20px", marginRight: "100px" }}
        >
          <ul>
            {asientosReservados.map((asiento, index) => (
              <li key={index}>{asiento}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Reservar;

import React, { useState } from "react";
import { Button } from "@tremor/react";
import { BiArrowBack } from "react-icons/bi";
import Selector from "../Ventafisica/selector";
import Asientos from "../Ventafisica/asientos";
import Formulario from "../Ventafisica/formulario";
import Reservar from "../Ventafisica/reservar";

const Comprar = () => {
  const [eventoSeleccionado, setEventoSeleccionado] = useState(null);
  const [precioEvento, setPrecioEvento] = useState(null);
  const [precioEventoVIP, setPrecioEventoVIP] = useState(null);
  const [asientoSeleccionado, setAsientoSeleccionado] = useState(null);
  const [precioAsiento, setPrecioAsiento] = useState(null);
  const [zonaAsiento, setZonaAsiento] = useState(null);
  const [tipoAsiento, setTipoAsiento] = useState(null);

  const handleSeleccionEvento = (eventoId, costo, costoVip) => {
    setEventoSeleccionado(eventoId);
    setPrecioEvento(costo);
    setPrecioEventoVIP(costoVip);
  };

  const handleSeleccionAsiento = (asiento, precio, zona, tipo) => {
    setAsientoSeleccionado(asiento);
    setPrecioAsiento(precio);
    setZonaAsiento(zona);
    setTipoAsiento(tipo);
  };

  const handleRecargarPagina = () => {
    window.location.reload();
  };

  return (
    <div className="relative flex flex-col justify-center items-center p-4 space-y-6">
      <Button
        icon={BiArrowBack}
        onClick={handleRecargarPagina}
        className="absolute top-0 left-4 border-2 border-gray-300 text-gray-300 rounded-lg"
        color="white"
        size="lg"
      />

      {/* Selector de Eventos */}
      <div className="w-full max-w-sm">
        <Selector onEventoSeleccionado={handleSeleccionEvento} />
      </div>

      {/* Mostrar detalles solo si se ha seleccionado un evento */}
      {eventoSeleccionado && (
        <div className="w-full flex justify-between space-x-4">
          {/* Lista de Asientos */}
          <div className="w-2/5">
            <Asientos
              eventoId={eventoSeleccionado}
              precioEvento={precioEvento}
              precioEventoVIP={precioEventoVIP}
              onAsientoSeleccionado={handleSeleccionAsiento}
            />
          </div>

          {/* Formulario y Reservas */}
          <div className="w-3/5 flex flex-col space-y-6">
            <Formulario
              asientoSeleccionado={asientoSeleccionado}
              precioAsiento={precioAsiento}
              zonaAsiento={zonaAsiento}
              tipoAsiento={tipoAsiento}
            />
            <Reservar eventId={eventoSeleccionado} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Comprar;

import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { EnvironmentContext } from "../../EnvironmentVariables";

const SeleccionEventosActivos = ({ onEventoSeleccionado }) => {
  const [eventos, setEventos] = useState([]);
  const [eventoSeleccionadoId, setEventoSeleccionadoId] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 3;
  const containerRef = useRef(null);

  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    cargarEventos();
  }, []);

  const cargarEventos = async () => {
    try {
      const response = await axios.get(`${backendUrl}/infoeventos.php`);
      const eventosActivos = response.data.filter(
        (evento) => evento.state === "activo"
      );
      setEventos(eventosActivos);
    } catch (error) {
      console.error("Error al cargar eventos:", error);
    }
  };

  const handleEventoSeleccionado = (eventoId) => {
    setEventoSeleccionadoId(eventoId);
    const eventoSeleccionado = eventos.find((evento) => evento.id === eventoId);
    onEventoSeleccionado(eventoSeleccionado);
  };

  const handleScroll = (e) => {
    const container = containerRef.current;
    if (e.deltaY > 0 && startIndex + itemsPerPage < eventos.length) {
      setStartIndex(startIndex + itemsPerPage);
    } else if (e.deltaY < 0 && startIndex > 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };

  return (
    <div
      ref={containerRef}
      onWheel={handleScroll}
      className="overflow-y-auto"
      style={{ maxHeight: "900px" }}
    >
      {eventos.length === 0 ? (
        <div className="text-gray-500">No hay eventos activos</div>
      ) : (
        <div className="flex flex-col items-center space-y-4">
          {eventos
            .slice(startIndex, startIndex + itemsPerPage)
            .map((evento) => (
              <div
                key={evento.id}
                className={`rounded-lg p-4 m-2 cursor-pointer border border-gray-300 ${
                  evento.id === eventoSeleccionadoId
                    ? "bg-gray-500 text-white"
                    : "hover:bg-gray-200"
                }`}
                onClick={() => handleEventoSeleccionado(evento.id)}
                style={{ width: "300px", height: "150px" }}
              >
                <h3 className="text-lg font-bold">{evento.title}</h3>
                <p>Fecha: {evento.date}</p>
                <p>Hora: {evento.time}</p>
              </div>
            ))}
          {eventos.slice(startIndex, startIndex + itemsPerPage).length <
            itemsPerPage &&
            Array.from({
              length:
                itemsPerPage -
                eventos.slice(startIndex, startIndex + itemsPerPage).length,
            }).map((_, index) => (
              <div
                key={index}
                className="rounded-lg p-4 m-2 border border-gray-300 animate-pulse"
                style={{ width: "300px", height: "150px" }}
              >
                <div className="h-6 bg-gray-300 mb-2 rounded"></div>
                <div className="h-4 bg-gray-300 mb-2 rounded"></div>
                <div className="h-4 bg-gray-300 mb-2 rounded"></div>
                <div className="h-4 bg-gray-300 mb-2 rounded"></div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SeleccionEventosActivos;

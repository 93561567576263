import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EnvironmentContext } from "../../EnvironmentVariables"; // Importa el contexto de las variables de entorno
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";
import { FaClock } from "react-icons/fa"; // Importa el icono de reloj de FontAwesome
import { TextInput } from "@tremor/react"; // Importa el componente TextInput de Tremor

const Entradas = ({ eventoId }) => {
  const [transacciones, setTransacciones] = useState([]);
  const [filtro, setFiltro] = useState(""); // Estado para almacenar el valor del filtro
  const { backendUrl } = useContext(EnvironmentContext); // Obtiene el backendUrl del contexto
  const [inicio, setInicio] = useState(0);
  const cantidadPorPagina = 10;

  useEffect(() => {
    // Función para obtener las transacciones
    const obtenerTransacciones = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/asistenciaadmin.php?eventoId=${eventoId}`
        );
        setTransacciones(response.data);
      } catch (error) {
        console.error("Error al obtener transacciones:", error);
      }
    };

    // Llamada inicial para obtener las transacciones
    obtenerTransacciones();

    // Configuración del temporizador para actualizar las transacciones periódicamente
    const intervalId = setInterval(obtenerTransacciones, 5000); // Actualiza cada 5 segundos

    // Limpieza del temporizador al desmontar el componente
    return () => clearInterval(intervalId);
  }, [backendUrl, eventoId]);

  const marcarAsistencia = async (idTransaccion) => {
    try {
      const response = await axios.post(
        `${backendUrl}/asistencia.php`, // Utiliza el backendUrl para construir la URL de la solicitud
        { id: idTransaccion }
      );
      console.log(response.data);
      // Actualizar el estado de la transacción marcada
      setTransacciones((prevTransacciones) =>
        prevTransacciones.map((transaccion) =>
          transaccion.id === idTransaccion
            ? { ...transaccion, state: "canjeado" }
            : transaccion
        )
      );
    } catch (error) {
      console.error("Error al marcar la asistencia:", error);
    }
  };

  const handleSiguiente = () => {
    setInicio(inicio + cantidadPorPagina);
  };

  const handleAnterior = () => {
    setInicio(Math.max(0, inicio - cantidadPorPagina));
  };

  const filtrarTransacciones = (transacciones) => {
    return transacciones.filter((transaccion) => {
      // Filtra por id, email, username, seat_number y amount
      return (
        transaccion.id.toLowerCase().includes(filtro.toLowerCase()) ||
        transaccion.email.toLowerCase().includes(filtro.toLowerCase()) ||
        transaccion.username.toLowerCase().includes(filtro.toLowerCase()) ||
        transaccion.seat_number.toLowerCase().includes(filtro.toLowerCase()) ||
        transaccion.amount.toLowerCase().includes(filtro.toLowerCase())
      );
    });
  };

  return (
    <div className="mx-auto max-w-2xl">
      <TextInput
        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
        placeholder="Filtrar..."
      />
      <div
        className="card"
        style={{ width: "100%", height: "500px", overflow: "auto" }}
      >
        <Table
          style={{ width: "100%", tableLayout: "fixed", fontSize: "0.8em" }}
        >
          <TableHead>
            <TableRow>
              <TableHeaderCell>ID Transacción</TableHeaderCell>
              <TableHeaderCell>Email</TableHeaderCell>
              <TableHeaderCell>Usuario</TableHeaderCell>
              <TableHeaderCell>Asiento</TableHeaderCell>
              <TableHeaderCell>Monto</TableHeaderCell>
              <TableHeaderCell>Estado</TableHeaderCell>
              <TableHeaderCell>Acción</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtrarTransacciones(transacciones)
              .slice(inicio, inicio + cantidadPorPagina)
              .map((transaccion) => (
                <TableRow key={transaccion.id}>
                  <TableCell>{transaccion.id}</TableCell>
                  <TableCell
                    style={{
                      maxWidth: "300px",

                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {transaccion.email}
                  </TableCell>
                  <TableCell>{transaccion.username}</TableCell>
                  <TableCell>{transaccion.seat_number}</TableCell>
                  <TableCell>{transaccion.amount}</TableCell>
                  <TableCell>
                    {transaccion.state !== null && transaccion.state !== "" ? (
                      transaccion.state
                    ) : (
                      <FaClock /> // Muestra el icono de reloj si el estado es nulo o vacío
                    )}
                  </TableCell>
                  <TableCell style={{ padding: "10px" }}>
                    {transaccion.state !== "canjeado" && (
                      <button
                        className="bg-black text-white px-2 py-1 rounded"
                        onClick={() => marcarAsistencia(transaccion.id)}
                      >
                        Confirmar
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="mt-4 flex justify-between">
        <button
          className="text-black px-2 py-1 rounded"
          onClick={handleAnterior}
          disabled={inicio === 0}
        >
          Anterior
        </button>
        <button
          className="text-black px-2 py-1 rounded"
          onClick={handleSiguiente}
          disabled={inicio + cantidadPorPagina >= transacciones.length}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Entradas;

// EnvironmentVariables.js
import React, { createContext } from "react";

// Crea el contexto
export const EnvironmentContext = createContext();

const EnvironmentVariables = ({ children }) => {
  // Define las variables de entorno aquí
  const backendUrl = "https://backend.ccibarcelona.org";
  //const backendUrl = "http://localhost/ccibarcelona/";
  const apiUrl = `${backendUrl}/api`;

  // Retorna un contexto con las variables de entorno
  return (
    <EnvironmentContext.Provider value={{ backendUrl, apiUrl }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentVariables;

import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ClipLoader } from "react-spinners";

const SpinnerModal = ({ isOpen, onClose, message }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50 flex items-center justify-center"
        onClose={onClose}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <ClipLoader color={"#4A90E2"} loading={true} size={40} />
            <p className="text-lg font-semibold">{message}</p>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default SpinnerModal;

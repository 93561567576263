import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  HiMenu,
  HiChevronUp,
  HiChevronDown,
  HiDocumentAdd,
  HiPencilAlt,
  HiOutlineCog,
  HiArrowUp,
  HiUser,
  HiCog,
  HiCollection,
  HiClipboardCheck,
  HiAdjustments,
  HiOutlineX,
  HiTag,
} from "react-icons/hi";
import DataDefault from "../data";

const Nav1 = ({ toggleMenu }) => {
  const [menuDesplegado, setMenuDesplegado] = useState(true);
  const [selectedOption, setSelectedOption] = useState("inicio");
  const location = useLocation();

  useEffect(() => {
    const menuState = localStorage.getItem("menuState");
    if (menuState) {
      setMenuDesplegado(menuState === "true");
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/dashboard") {
      setSelectedOption("inicio");
    } else if (pathname === "/dashboard/event-creation") {
      setSelectedOption("crear");
    } else if (pathname === "/dashboard/event-compra") {
      setSelectedOption("compra");
    } else if (pathname === "/dashboard/event-list") {
      setSelectedOption("editar");
    } else if (pathname === "/dashboard/event-configuration") {
      setSelectedOption("configurar");
    } else if (pathname === "/dashboard/event_activate") {
      setSelectedOption("activar");
    } else if (pathname === "/dashboard/event-admin") {
      setSelectedOption("administrar");
    } else if (pathname === "/dashboard/event-desactivate") {
      setSelectedOption("desactivar");
    } else if (pathname === "/dashboard/event-assistance") {
      setSelectedOption("asistencia");
    }
  }, [location]);

  const toggleDesplegar = () => {
    const newState = !menuDesplegado;
    setMenuDesplegado(newState);
    localStorage.setItem("menuState", newState.toString());
  };

  return (
    <div>
      <div className="hidden lg:block font-poppins border rounded m-6 w-[250px] h-[900px] ">
        <button className="focus:outline-none lg:hidden" onClick={toggleMenu}>
          <HiMenu className="text-3xl" />
        </button>

        <div className="mt-6">
          <Link to="/" className="items-center text-2xl text-bold p-5">
            Panel De Control
          </Link>
          <hr />
          <ul className="mt-2 pl-4 p-4">
            <li>
              <Link
                to="/dashboard"
                className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                  selectedOption === "inicio" && "bg-blue-200 text-blue-500"
                }`}
              >
                <HiDocumentAdd className="mr-2" />
                Inicio
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/event-compra"
                className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                  selectedOption === "compra" && "bg-blue-200 text-blue-500"
                }`}
              >
                <HiTag className="mr-2" />
                Formulario De Venta
              </Link>
            </li>
          </ul>

          <hr />
          <div className="flex items-center justify-between">
            <button
              className="flex items-center focus:outline-none text-gray-600"
              onClick={toggleDesplegar}
            >
              <span className="mr-2 p-3">Eventos</span>
              {menuDesplegado ? (
                <HiChevronUp className="w-6 h-6" />
              ) : (
                <HiChevronDown className="w-6 h-6" />
              )}
            </button>
          </div>
          {menuDesplegado && (
            <ul className="mt-2 pl-4 p-4">
              <li>
                <Link
                  to="/dashboard/event-creation"
                  className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                    selectedOption === "crear" && "bg-blue-200 text-blue-500"
                  }`}
                >
                  <HiDocumentAdd className="mr-2" />
                  Crear Evento
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/event-list"
                  className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                    selectedOption === "editar" && "bg-blue-200 text-blue-500"
                  }`}
                >
                  <HiAdjustments className="mr-2" />
                  Configurar Eventos
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/event-configuration"
                  className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                    selectedOption === "configurar" &&
                    "bg-blue-200 text-blue-500"
                  }`}
                >
                  <HiOutlineCog className="mr-2" /> Configurar Asientos
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/event_activate"
                  className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                    selectedOption === "activar" && "bg-blue-200 text-blue-500"
                  }`}
                >
                  <HiClipboardCheck className="mr-2" /> Activar Eventos
                </Link>
              </li>
            </ul>
          )}
          <hr />
          <div className="flex items-center justify-between">
            <button
              className="flex items-center focus:outline-none text-gray-600"
              onClick={() => alert("Click en Configuración")}
            >
              <span className="mr-2 p-3">Configuración</span>
              <HiCog className="w-6 h-6" />
            </button>
          </div>
          {/* Este es el nuevo apartado de configuración */}
          <ul className="mt-2 pl-4 p-4">
            <li>
              <Link
                to="/dashboard/event-admin"
                className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                  selectedOption === "administrar" &&
                  "bg-blue-200 text-blue-500"
                }`}
              >
                <HiCollection className="mr-2" /> Administrar Eventos
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/event-assistance"
                className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                  selectedOption === "asistencia" && "bg-blue-200 text-blue-500"
                }`}
              >
                <HiUser className="mr-2" /> Asistencia
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/event-desactivate"
                className={`flex items-center py-2 px-4 mb-5 rounded-md hover:bg-gray-200 focus:outline-none ${
                  selectedOption === "desactivar" && "bg-blue-200 text-blue-500"
                }`}
              >
                <HiOutlineX className="mr-2" /> Terminar Evento
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav1;

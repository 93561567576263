import React, { useState } from "react";
import Administrar from "./Administrar";
import SeleccionEventosActivos from "./SeleccionEventosActivos";

const ConfigOn = () => {
  const [eventoSeleccionado, setEventoSeleccionado] = useState(null);
  const [eventoIdSeleccionado, setEventoIdSeleccionado] = useState(null);

  const onEventoActualizado = () => {
    console.log("Evento actualizado");
  };

  return (
    <div>
      <div className="">
        <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-start">
          <div className="lg:col-span-6 lg:col-start-1 lg:row-start-1 lg:row-span-1">
            <Administrar
              eventoId={eventoIdSeleccionado}
              eventoSeleccionado={eventoSeleccionado}
              onEventoActualizado={onEventoActualizado}
            />
          </div>
          <div className="lg:col-span-6 lg:col-start-7 lg:row-start-1 lg:row-span-1">
            <SeleccionEventosActivos
              onEventoSeleccionado={(evento) => {
                setEventoSeleccionado(evento);
                setEventoIdSeleccionado(evento.id);
              }}
              onEventoIdSeleccionado={(eventoId) =>
                setEventoIdSeleccionado(eventoId)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigOn;

import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Profile from "../perfil";
import Init from "../log/init";
import logo from "../img/logo.png"; // Importa la imagen del logo

const Navbar = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <nav
      className="p-6"
      style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}
    >
      <div className="container mx-auto flex items-center justify-between">
        <Link to="/" className="flex items-center text-1xl">
          <img src={logo} alt="CCI EVENTS Logo" className="h-12 w-12 mr-2" />
          <p
            translate="no"
            className="hidden md:inline-block font-medium text-black"
          >
            CCI CONDAL BCN
          </p>
        </Link>
        <div className="flex items-center">
          <div className="hidden md:inline-block">
            {isAuthenticated ? <Profile /> : <Init />}
          </div>
          <div className="md:hidden">
            {isAuthenticated ? <Profile /> : <Init />}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EnvironmentContext } from "../../EnvironmentVariables";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextInput,
} from "@tremor/react";

const Asientos = ({
  eventoId,
  precioEvento,
  precioEventoVIP,
  onAsientoSeleccionado,
}) => {
  const [loading, setLoading] = useState(true);
  const [asientos, setAsientos] = useState([]);
  const [totalAsientos, setTotalAsientos] = useState(0);
  const [paginaActual, setPaginaActual] = useState(1);
  const [asientosPorPagina] = useState(10);
  const [filtro, setFiltro] = useState("");
  const [mensajeError, setMensajeError] = useState("");
  const { backendUrl } = useContext(EnvironmentContext);
  const [asientoSeleccionado, setAsientoSeleccionado] = useState(null);
  const [asientosPrevios, setAsientosPrevios] = useState([]);

  useEffect(() => {
    const fetchAsientos = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/asientos.php?eventId=${eventoId}`
        );
        if (response.data && Array.isArray(response.data)) {
          const nuevosAsientos = response.data.filter(
            (asiento) =>
              asiento.event_id === eventoId && asiento.is_available === "si"
          );

          // Comparar con los asientos previos para detectar cambios
          if (
            JSON.stringify(nuevosAsientos) !== JSON.stringify(asientosPrevios)
          ) {
            setAsientos(nuevosAsientos);
            setTotalAsientos(nuevosAsientos.length);
            setAsientosPrevios(nuevosAsientos);
          }
        } else {
          console.error("Datos de asientos no válidos:", response.data);
        }
      } catch (error) {
        console.error("Error al obtener asientos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAsientos();
    const intervalId = setInterval(fetchAsientos, 5000); // Consultar cada 5 segundos

    return () => clearInterval(intervalId);
  }, [eventoId, backendUrl, asientosPrevios]);

  const handleSeleccionAsiento = (asiento) => {
    setAsientoSeleccionado(asiento);
    setMensajeError(""); // Limpiar cualquier mensaje de error anterior
    const tipoAsiento = asiento.vip === "si" ? "VIP" : "Normal";
    onAsientoSeleccionado(
      asiento,
      asiento.vip === "si" ? precioEventoVIP : precioEvento,
      asiento.groupnumber, // Zona del asiento
      tipoAsiento // Tipo del asiento
    );
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    const totalPaginas = Math.ceil(totalAsientos / asientosPorPagina);
    if (paginaActual < totalPaginas) {
      setPaginaActual(paginaActual + 1);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      const asientoFiltrado = asientos.find((asiento) =>
        asiento.seat_number.toLowerCase().includes(filtro.toLowerCase())
      );
      if (asientoFiltrado) {
        handleSeleccionAsiento(asientoFiltrado);
      } else {
        setMensajeError("Asiento no encontrado");
      }
    }
  };

  const asientosFiltrados = asientos.filter((asiento) =>
    asiento.seat_number.toLowerCase().includes(filtro.toLowerCase())
  );

  const indiceUltimoAsiento = paginaActual * asientosPorPagina;
  const indicePrimerAsiento = indiceUltimoAsiento - asientosPorPagina;
  const asientosActuales = asientosFiltrados.slice(
    indicePrimerAsiento,
    indiceUltimoAsiento
  );

  return (
    <>
      <h2 className="text-lg font-semibold mb-2">Asientos Disponibles</h2>
      <TextInput
        placeholder="Filtrar asientos"
        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
        onKeyDown={handleEnter}
      />
      {mensajeError && <p className="text-red-500">{mensajeError}</p>}
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número de Asiento</TableCell>
                <TableCell>Grupo</TableCell>
                <TableCell>Precio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {asientosActuales.map((asiento, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleSeleccionAsiento(asiento)}
                  style={{
                    cursor: "pointer",
                    background:
                      asientoSeleccionado === asiento ? "lightblue" : "inherit",
                  }}
                >
                  <TableCell>{asiento.seat_number}</TableCell>
                  <TableCell>{asiento.groupnumber}</TableCell>
                  <TableCell>
                    {asiento.vip === "si"
                      ? `$${precioEventoVIP}`
                      : `$${precioEvento}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className="mt-4 flex justify-between">
            <button
              onClick={handlePaginaAnterior}
              disabled={paginaActual === 1}
              className="text-black"
            >
              Anterior
            </button>
            <button
              onClick={handlePaginaSiguiente}
              disabled={paginaActual * asientosPorPagina >= totalAsientos}
              className="text-black"
            >
              Siguiente
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Asientos;

import React, { useState } from "react";
import Estandar from "./estandar"; // Importa el componente Estandar
import Vip from "./vip"; // Importa el componente Vip
import EventoGratis from "./eventogratis"; // Importa el componente Gratis

const ComponenteContenedor = ({
  onAsientosSeleccionadosChange,
  tipoEvento,
  onAsientosSeleccionadosCompra,
  event, // Agrega event como una prop
}) => {
  const [grupoSeleccionado, setGrupoSeleccionado] = useState("estandar");

  const handleSeleccionGrupo = (grupo) => {
    setGrupoSeleccionado(grupo);
  };

  const handleAsientosSeleccionadosChange = (asientosSeleccionados) => {
    // Llama a la función para pasar los asientos seleccionados a Compra
    onAsientosSeleccionadosCompra(asientosSeleccionados);
  };

  // Determina si el evento es gratis
  const esEventoGratis = event && parseFloat(event.cost) === 0;

  // Si el evento es gratis, selecciona automáticamente el grupo "gratis"
  if (esEventoGratis && grupoSeleccionado !== "gratis") {
    setGrupoSeleccionado("gratis");
  }

  return (
    <div className="flex justify-center mt-10">
      <div className="flex flex-col w-[670px] ">
        <div className="flex flex-col">
          <div className="flex space-x-2 flex-wrap">
            <div
              className={`px-4 py-2 ${
                grupoSeleccionado === "estandar"
                  ? "border text-black"
                  : "bg-gray-200 text-gray-700"
              } font-medium rounded-t-md cursor-pointer`}
              onClick={() => handleSeleccionGrupo("estandar")}
            >
              Estándar
            </div>
            {/* Mostrar la pestaña VIP solo si el evento contiene asientos VIP */}
            {tipoEvento === "Contiene Asientos Vip" && (
              <div
                className={`px-4 py-2 ${
                  grupoSeleccionado === "vip"
                    ? "border text-black"
                    : "bg-gray-200 text-gray-700"
                } font-medium rounded-t-md cursor-pointer`}
                onClick={() => handleSeleccionGrupo("vip")}
              >
                VIP
              </div>
            )}
            {/* Mostrar la pestaña Gratis si el evento es gratis */}
            {esEventoGratis && (
              <div
                className={`px-4 py-2 ${
                  grupoSeleccionado === "gratis"
                    ? "border text-black"
                    : "bg-gray-200 text-gray-700"
                } font-medium rounded-t-md cursor-pointer`}
                onClick={() => handleSeleccionGrupo("gratis")}
              >
                Gratis
              </div>
            )}
          </div>
        </div>
        <div className="shadow-lg rounded-b border">
          {/* Renderiza el componente Estandar si la pestaña seleccionada es "estandar" */}
          {grupoSeleccionado === "estandar" && (
            <Estandar
              onAsientosSeleccionadosChange={handleAsientosSeleccionadosChange} // Cambio aquí
              tipoEvento={tipoEvento} // Pasar el tipo de evento al componente Estandar
            />
          )}
          {/* Renderiza el componente VIP si la pestaña seleccionada es "vip" */}
          {grupoSeleccionado === "vip" && (
            <Vip
              onAsientosSeleccionadosChange={handleAsientosSeleccionadosChange}
            />
          )}
          {/* Renderiza el componente Gratis si la pestaña seleccionada es "gratis" */}
          {grupoSeleccionado === "gratis" && (
            <EventoGratis
              onAsientosSeleccionadosChange={handleAsientosSeleccionadosChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ComponenteContenedor;

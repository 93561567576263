import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { EnvironmentContext } from "../../EnvironmentVariables";
import { FaTicketAlt, FaCheck, FaTimesCircle } from "react-icons/fa";
import { NumberInput } from "@tremor/react";

const EventoGratis = ({ onAsientosSeleccionadosChange, tipoEvento }) => {
  const [loading, setLoading] = useState(true);
  const [seatGroups, setSeatGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("A"); // Ajuste para que por defecto se seleccione "A"
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const { id } = useParams();
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/asientos.php?eventId=${id}`
        );
        const filteredSeats = response.data.filter(
          (seat) =>
            ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(
              seat.groupnumber
            ) && seat.is_available === "si"
        );
        setSeatGroups(filteredSeats);
      } catch (error) {
        console.error("Error fetching seat data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [backendUrl, id]);

  useEffect(() => {
    const hasVipSeats = seatGroups.some((seat) => seat.groupnumber > "E");
    setSelectedGroup(hasVipSeats ? "A" : "J");
  }, [seatGroups]);

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setSelectedSeats([]);
    setQuantity(0);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    const availableSeatsCount = seatGroups.filter(
      (seat) => seat.groupnumber === selectedGroup && seat.is_available === "si"
    ).length;

    if (newQuantity > availableSeatsCount) {
      setQuantity(availableSeatsCount);
    } else {
      setQuantity(newQuantity);
    }

    setSelectedSeats([]);
  };

  const handleAddToCart = () => {
    let selected = [...selectedSeats];

    if (quantity > selectedSeats.length) {
      const availableSeats = seatGroups.filter(
        (seat) =>
          seat.groupnumber === selectedGroup && seat.is_available === "si"
      );
      const remaining = quantity - selectedSeats.length;
      for (let i = 0; i < remaining; i++) {
        const randomIndex = Math.floor(Math.random() * availableSeats.length);
        selected.push(availableSeats[randomIndex].id);
      }
    }

    onAsientosSeleccionadosChange(selected);
    setSelectedSeats([]);
    setQuantity(0);
  };

  const seatGroupOptions = Array.from({ length: 10 }, (_, index) => {
    const group = String.fromCharCode(65 + index);
    return (
      <option key={group} value={group}>
        {group}
      </option>
    );
  });

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col w-full">
        <div className="rounded-lg p-8 w-full">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <FaTicketAlt className="text-3xl text-[#6200ee] mr-2" />
              <h2 className="text-2xl font-bold">Gratis</h2>
              <span className="text-3xl font-bold text-[#6200ee] ml-6">
                {selectedGroup}
              </span>
            </div>
            <div>
              <label className="block text-gray-700 font-medium" htmlFor="zone">
                Zona
              </label>
              <div className="relative">
                <select
                  translate="no"
                  className="rounded w-14 border-2 border-gray-200 h-10 bg-transparent"
                  id="zone"
                  onChange={(e) => handleGroupSelect(e.target.value)}
                  value={selectedGroup}
                >
                  {seatGroupOptions}
                </select>
              </div>
            </div>
          </div>
          <p className="mb-4">Tipo de evento: {tipoEvento}</p>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 font-medium"
                htmlFor="ticket-quantity"
              >
                Cantidad de Entradas
              </label>
              <NumberInput
                className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                id="ticket-quantity"
                type="number"
                min="0"
                value={quantity}
                onChange={handleQuantityChange}
              />
            </div>
            <div className="flex items-center">
              <p className="text-gray-700 text-lg">
                Asientos disponibles:{" "}
                {
                  seatGroups.filter(
                    (seat) =>
                      seat.groupnumber === selectedGroup &&
                      seat.is_available === "si"
                  ).length
                }
              </p>
            </div>
          </div>
          <button
            className="w-full bg-[#6200ee] hover:bg-[#7c4dff] text-white font-medium py-2 px-4 rounded-md flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-[#6200ee] focus:ring-opacity-50"
            onClick={handleAddToCart}
          >
            <FaCheck className="mr-2" />
            Agregar al Carrito
          </button>
          {!loading && seatGroups.length === 0 && (
            <div className="mt-4 text-red-500 flex items-center justify-center">
              <FaTimesCircle className="mr-2" />
              No hay asientos disponibles en este momento.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventoGratis;

import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import logoblanco from "../../img/logo.png"; // Ajusta la ruta según tu estructura de archivos

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="w-full md:w-[61%] mx-auto bg-black bg-cover bg-center text-white flex justify-center"
      style={{
        backgroundImage:
          'url("https://assets.website-files.com/63904f663019b0d8edf8d57c/6399731b4cdf484f42d3d80b_Mask%20group%20(2).svg")',
      }}
    >
      <div className="container mx-auto px-5 py-16 md:px-10 md:py-24 lg:py-32">
        <div className="flex flex-col items-center">
          <a href="#" className="mb-8 inline-block max-w-full">
            <img
              src={logoblanco}
              alt="CCI CONDAL BARCELONA"
              className="inline-block max-h-20"
            />
          </a>
          <div className="mb-12 flex justify-center gap-2">
            <a
              href="https://www.facebook.com/cci.condal.barcelona"
              className="font-bold flex flex-col"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="text-2xl" />
            </a>
            <a
              href="https://www.instagram.com/cci.condal.barcelona"
              className="font-bold flex flex-col"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-2xl" />
            </a>
          </div>
          <div className="text-center mb-8">
            <p>Carrer de Maracaibo, 32, Sant Andreu, 08030 Barcelona</p>
            <p>ccidaniel2004@yahoo.es</p>
            <p>Teléfono: +34 689 4986 25</p>
          </div>
          <p className="text-sm md:text-base">
            © {currentYear} CCI CONDAL BARCELONA.
          </p>
          <p className="text-xs md:text-sm">Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

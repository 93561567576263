import React, { useState, useEffect, useRef, useContext } from "react";
import jsQR from "jsqr";
import Swal from "sweetalert2";
import sonidoURL from "./sonido.mp3";
import { FaCamera } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { EnvironmentContext } from "../../EnvironmentVariables";
import Acceso from "../../log/Acceso";

const Entradas = () => {
  const [serverResponse, setServerResponse] = useState("");
  const videoRef = useRef(null);
  const audioRef = useRef(new Audio(sonidoURL));
  const { backendUrl } = useContext(EnvironmentContext);
  const canSendRequestRef = useRef(true);

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.log("Error accessing camera: " + err);
      }
    };

    getUserMedia();

    const scanQRCode = () => {
      const video = videoRef.current;
      const canvas = document.createElement("canvas");
      const canvasContext = canvas.getContext("2d");

      if (
        video &&
        canvas &&
        canvasContext &&
        video.videoWidth > 0 &&
        video.videoHeight > 0
      ) {
        const width = video.videoWidth;
        const height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        canvasContext.drawImage(video, 0, 0, width, height);

        const imageData = canvasContext.getImageData(0, 0, width, height);
        const code = jsQR(imageData.data, width, height);

        if (code && canSendRequestRef.current) {
          canSendRequestRef.current = false;
          audioRef.current.play();
          sendTransactionData(code.data);
        }
      }

      requestAnimationFrame(scanQRCode);
    };

    const sendTransactionData = async (qrData) => {
      try {
        const id = qrData
          .split("&")
          .find((pair) => pair.startsWith("id="))
          .split("=")[1];
        const response = await fetch(`${backendUrl}/asistencia.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        });
        if (response.ok) {
          const responseData = await response.json();
          console.log("Respuesta del servidor:", responseData);
          console.log("Éxito de la transacción:", responseData.success);
          setServerResponse(responseData.message);
          if (responseData.success) {
            // Verificar el éxito de la transacción
            Swal.fire({
              icon: "success",
              title: "Invitación Válida",
              showCloseButton: true,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onClose: () => {
                window.location.reload(); // Recargar la página al cerrar la alerta
              },
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error en la transacción",
              text: "Hubo un error al actualizar el estado de la transacción.",
              showCloseButton: true,
              showConfirmButton: false,
              allowOutsideClick: false,
            });
          }
        } else {
          throw new Error("Error al actualizar el estado de la transacción");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        canSendRequestRef.current = true;
      }
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener("loadedmetadata", () => {
        requestAnimationFrame(scanQRCode);
      });
    }

    return () => {
      if (video) {
        video.removeEventListener("loadedmetadata", () => {
          requestAnimationFrame(scanQRCode);
        });
      }
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-black text-white">
      <Acceso />
      <header className="flex justify-center items-center w-full py-4">
        <FaCamera className="mr-2" size={24} />
        <h1 className="text-2xl font-semibold">Escaneo de QR</h1>
        <IoQrCodeOutline className="ml-2" size={24} />
      </header>
      <div className="relative w-full max-w-md mt-8">
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          className="w-full rounded-md"
          style={{ aspectRatio: "1/1" }}
        />
      </div>
      <div className="mt-8 p-4 bg-white rounded-md shadow-md">
        <p className="text-lg font-semibold text-gray-800 mb-2">
          Respuesta del servidor:
        </p>
        <p className="text-base text-gray-700">
          {serverResponse || "Esperando respuesta del servidor..."}
        </p>
      </div>
    </div>
  );
};

export default Entradas;

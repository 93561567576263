import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { EnvironmentContext } from "../../EnvironmentVariables";
import { NumberInput } from "@tremor/react";
import {
  FaCheckCircle,
  FaShoppingCart,
  FaCouch,
  FaMapMarkerAlt,
} from "react-icons/fa";

const Estandar = ({ onAsientosSeleccionadosChange, tipoEvento }) => {
  const [loading, setLoading] = useState(true);
  const [seatGroups, setSeatGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("A");
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { id } = useParams();
  const { backendUrl } = useContext(EnvironmentContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/asientos.php?eventId=${id}`
        );
        const filteredSeats = response.data.filter(
          (seat) =>
            ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(
              seat.groupnumber
            ) && seat.is_available === "si"
        );
        setSeatGroups(filteredSeats);
      } catch (error) {
        console.error("Error fetching seat data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [backendUrl, id]);

  useEffect(() => {
    const hasVipSeats = seatGroups.some((seat) => seat.groupnumber > "E");
    setSelectedGroup(hasVipSeats ? "A" : "J");
  }, [seatGroups]);

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setSelectedSeats([]);
    setQuantity(0);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    const availableSeatsCount = seatGroups.filter(
      (seat) => seat.groupnumber === selectedGroup && seat.is_available === "si"
    ).length;

    if (newQuantity > availableSeatsCount) {
      setQuantity(availableSeatsCount);
    } else {
      setQuantity(newQuantity);
    }

    setSelectedSeats([]);
  };

  const handleAddToCart = () => {
    let selected = [...selectedSeats];

    if (quantity > selectedSeats.length) {
      const availableSeats = seatGroups.filter(
        (seat) =>
          seat.groupnumber === selectedGroup && seat.is_available === "si"
      );
      const remaining = quantity - selectedSeats.length;
      for (let i = 0; i < remaining; i++) {
        const randomIndex = Math.floor(Math.random() * availableSeats.length);
        selected.push(availableSeats[randomIndex].id);
      }
    }

    onAsientosSeleccionadosChange(selected);
    setSelectedSeats([]);
    setQuantity(0);
    setShowSuccessMessage(true);

    setTimeout(() => setShowSuccessMessage(false), 3000);
  };

  const seatGroupOptions =
    tipoEvento === "Contiene Asientos Vip"
      ? ["A", "B", "C", "D", "E"].map((group) => (
          <option translate="no" key={group} value={group}>
            {group}
          </option>
        ))
      : Array.from({ length: 10 }, (_, index) => {
          const group = String.fromCharCode(65 + index);
          return (
            <option key={group} value={group}>
              {group}
            </option>
          );
        });

  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-full p-6 shadow-lg rounded-lg ">
        <h2 className="text-2xl font-bold text-center text-[#6200ee] mb-4 flex items-center justify-center">
          Comprar Entradas <FaShoppingCart className="ml-2" />
          <span className="ml-4 text-lg text-gray-700">{selectedGroup}</span>
        </h2>

        <div className="float-end mb-4">
          <label className="block text-gray-700 font-medium" htmlFor="zone">
            Zona
          </label>
          <select
            translate="no"
            className="rounded w-14 border-2 border-gray-200 h-10 bg-transparent"
            id="zone"
            onChange={(e) => handleGroupSelect(e.target.value)}
            value={selectedGroup}
          >
            {seatGroupOptions}
          </select>
        </div>

        <div className="mb-4">
          <p className="text-gray-700 text-lg">
            Asientos disponibles:{" "}
            {
              seatGroups.filter(
                (seat) =>
                  seat.groupnumber === selectedGroup &&
                  seat.is_available === "si"
              ).length
            }
          </p>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-medium"
            htmlFor="ticket-quantity"
          >
            Cantidad de Entradas <FaCouch className="inline ml-1" />
          </label>
          <NumberInput
            className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            id="ticket-quantity"
            type="number"
            min="0"
            value={quantity}
            onChange={handleQuantityChange}
          />
        </div>

        <button
          className="w-full bg-[#6200ee] hover:bg-[#7c4dff] text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-[#6200ee] focus:ring-opacity-50"
          onClick={handleAddToCart}
        >
          Agregar al Carrito <FaCheckCircle className="inline ml-1" />
        </button>

        {showSuccessMessage && (
          <div className="mt-4 text-green-500 font-medium text-center">
            ¡Entradas agregadas al carrito con éxito!
          </div>
        )}

        <p className="mt-4 text-gray-600 text-center">{tipoEvento}</p>
      </div>
    </div>
  );
};

export default Estandar;
